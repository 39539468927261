import caxios from "@/axios.js"
import { getLastArticuleID } from '../../helpers/matf'
export default {
    namespaced: true,
    state: {
        selected:{
            profsys: null,
            constrType: null,
            frame: null,
            sash: null,
            impost: null,
            stub: null,
            sill: null,
            chopstick: null,
            sashInSash:null
        },
        profsys:[],
        constrTypes:[],
        articuls:[],
        profTypeIds:"2,7,6,14,5,8,15,16,17"
    },
    getters: {
        constrTypes: (state) => () => {
            if (!state.selected.profsys) return []

            return state.constrTypes.filter(obj =>
                obj.profsys_ids.split(',').map(Number).includes(state.selected.profsys)
            );

        },
        profsys: state => state.profsys,
        articuls: state => state.articuls,
        selected: state => key => state.selected[key],
        articulTypes:  (state) => (ids) => {
            if (!state.selected.profsys || !state.selected.constrType) return []

            return state.articuls.filter(obj =>
                ids.includes(Number(obj.parenttip))
            );

        },
    },
    mutations: {
        setSelectedProfileSystem(state, value) {
            state.selected.profsys = value;
            // Прямая фильтрация без геттеров
            const filteredConstrTypes = state.constrTypes.filter(obj =>
                obj.profsys_ids.split(',').map(Number).includes(value)
            );
            // Установка значения constrType
            state.selected.constrType = filteredConstrTypes.length > 0
                ? filteredConstrTypes[0].id
                : null;
        },
        setSelectedConstrTypes(state, value) {
            state.selected.constrType = value;
        },
        updateSelected(state, {key, value}) {
            state.selected[key] = value
        },
        
    },
    actions: {
        async getProfsys({ state, commit }) {
            if(state.profsys.length > 0) return;
            return caxios
                .get("/getProfsys?count=all")
                .then(response => {
                    state.profsys = response.data.body?.items||[];
                    if(state.profsys.length){
                        commit('setSelectedProfileSystem', state.profsys[0].id);
                    }
                    console.log(2)
                })
                .catch(() => {
                    console.log('Error')
                });
        },
        async getConstrTypes({ state, commit, dispatch }) {
            if(state.constrTypes.length > 0) return;
            return caxios
                .get("/getConstrtypes?count=all")
                .then(response => {
                    state.constrTypes = response.data.body?.items||[];
                    if(state.constrTypes.length){
                        commit('setSelectedConstrTypes', state.constrTypes[0].id);
                    }
                    console.log(4)
                })
                .catch(() => {
                    console.log('Error')
                });
        },
        async getArticuls({ state, dispatch }, profTypeId) {
            // if(state.articuls.length > 0) return;
            state.articuls = [];
            const { profsys, constrType } = state.selected
            if(!profsys || !constrType) return null;
            return caxios
                .get(`/getArticuls?profTypeId=${state.profTypeIds}&profId=${profsys}&constrTypeId=${constrType}&count=all`)
                .then(response => {
                    state.articuls = response.data.body?.items||[];
                    dispatch('updateArticulTypes');
                    console.log(5)
                })
                .catch(() => {
                    console.log('Error')
                });
        },
        updateArticulTypes({ state, getters }) {
            state.selected.frame = getLastArticuleID(getters.articulTypes([2]))
            state.selected.sash = getLastArticuleID(getters.articulTypes([7]))
            state.selected.impost = getLastArticuleID(getters.articulTypes([6]))
            state.selected.stub = getLastArticuleID(getters.articulTypes([14]))
            state.selected.sill = getLastArticuleID(getters.articulTypes([5]))
            state.selected.chopstick = getLastArticuleID(getters.articulTypes([8, 15]))
            state.selected.sashInSash = getLastArticuleID(getters.articulTypes([16]))
        }
    }
};