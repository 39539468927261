<template>
    <div>
      <main class="d-flex flex-nowrap">
        <div class="sidebar p-3 bg-body-tertiary" style="width: 300px;">
            <div class="list-group list-group-flush border-bottom scrollarea gap-2">
              <div class="d-flex gap-2 justify-content-center">
                <button class="btn btn-outline-secondary p-0" style="height: 40px;width: 40px;" @click="addImpost('H',2)">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 1H14C14.5523 1 15 1.44772 15 2V14C15 14.5523 14.5523 15 14 15H8.5V1ZM7.5 1H2C1.44772 1 1 1.44772 1 2V14C1 14.5523 1.44772 15 2 15H7.5V1ZM0 2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V2Z" fill="black"></path></svg>
                </button>
                <button class="btn btn-outline-secondary p-0" style="height: 40px;width: 40px;" @click="addImpost('H',3)">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 1H6V15H10V1ZM11 1V15H14C14.5523 15 15 14.5523 15 14V2C15 1.44772 14.5523 1 14 1H11ZM2 1H5V15H2C1.44772 15 1 14.5523 1 14V2C1 1.44772 1.44772 1 2 1ZM2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2Z" fill="black"></path>
                  </svg>
                </button>
                <button class="btn btn-outline-secondary p-0" style="height: 40px;width: 40px;" @click="addImpost('H',4)">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1 2C1 1.44772 1.44772 1 2 1H3.5V15H2C1.44772 15 1 14.5523 1 14V2ZM4.5 15V1H6.50009V15H4.5ZM7.50009 15H9.5V1H7.50009V15ZM12.5 15H10.5V1H12.5V15ZM13.5 15H14C14.5523 15 15 14.5523 15 14V2C15 1.44772 14.5523 1 14 1H13.5V15ZM3.5 16H4.5H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H3.5Z" fill="black"></path>
                  </svg>
                </button>
                <button class="btn btn-outline-secondary p-0" style="height: 40px;width: 40px;transform: rotate(-90deg);" @click="addImpost('V',2)">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 1H14C14.5523 1 15 1.44772 15 2V14C15 14.5523 14.5523 15 14 15H8.5V1ZM7.5 1H2C1.44772 1 1 1.44772 1 2V14C1 14.5523 1.44772 15 2 15H7.5V1ZM0 2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V2Z" fill="black"></path>
                  </svg>
                </button>
                <button class="btn btn-outline-secondary p-0" style="height: 40px;width: 40px;transform: rotate(-90deg);" @click="addImpost('V',3)">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 1H6V15H10V1ZM11 1V15H14C14.5523 15 15 14.5523 15 14V2C15 1.44772 14.5523 1 14 1H11ZM2 1H5V15H2C1.44772 15 1 14.5523 1 14V2C1 1.44772 1.44772 1 2 1ZM2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2Z" fill="black"></path>
                  </svg>
                </button>
                <button class="btn btn-outline-secondary p-0" style="height: 40px;width: 40px;transform: rotate(-90deg);" @click="addImpost('V',4)">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1 2C1 1.44772 1.44772 1 2 1H3.5V15H2C1.44772 15 1 14.5523 1 14V2ZM4.5 15V1H6.50009V15H4.5ZM7.50009 15H9.5V1H7.50009V15ZM12.5 15H10.5V1H12.5V15ZM13.5 15H14C14.5523 15 15 14.5523 15 14V2C15 1.44772 14.5523 1 14 1H13.5V15ZM3.5 16H4.5H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H3.5Z" fill="black"></path>
                  </svg>
                </button>
              </div>
              <button class="btn btn-outline-secondary" @click="addRadius"> Установить радиус </button>
              <button class="btn btn-outline-secondary" @click="addArcHeight"> Установить высоту дуги </button>
              <button class="btn btn-outline-danger" @click="removeImposts()"> Удалить разбивку </button>
              <button class="btn btn-outline-danger" @click="removeImposts('all')"> Удалить всю разбивку </button>
            </div>
        </div>
        <div class="constructor" ref="canvasContainer">
          <canvas id="constructor" :height="800" :width="800" ></canvas>
        </div>
      </main>
      <cModal
        id="sizeoutput"
        :visible="activeModal === 'sizeoutput'"
        @close="closeModal"
        title="Вывод размера"
        _class="modal-sm"
      >
        <template #default>
          <input type="number" class="form-control" v-model="sizeoutput">
        </template>
        <template #footer>
          <button @click="sizeoutputFN()" class="btn btn-primary">ok</button>
        </template>
      </cModal>
      <cModal
      id="imposts"
      :visible="activeModal === 'imposts'"
      @close="closeModal"
      title="Свойства профиля"
      _class="modal-md"
    >
      <template #default>
        <div class="mb-3 text-start" v-if="impostsMType==1">
          <label class="form-label mb-0">количество частей </label>
          <input type="number" class="form-control" v-model="addImpostsNum">
        </div>
        <div class="mb-3">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="impostProfType" id="inlineRadio1" value="1">
            <label class="form-check-label" for="inlineRadio1">Импост</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="impostProfType" id="inlineRadio2" value="2">
            <label class="form-check-label" for="inlineRadio2">Штульп левый</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="impostProfType" id="inlineRadio3" value="3">
            <label class="form-check-label" for="inlineRadio3">Штульп правый</label>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <div class="text-start">
              <label  class="form-label mb-0">профиль</label>
              <select class="form-select" v-model="selImpostProf" v-if="impostProfType==1">
                <option v-for="item in dImposts" :value="item.id" :key="item.id">{{item.articul}}</option>
              </select>
              <select class="form-select" v-model="selImpostProf" v-else>
                <option v-for="item in dShtulp" :value="item.id" :key="item.id" >{{item.articul}}</option>
              </select>
            </div>
          </div>
          <div class="col d-flex align-items-end">
            <div class="form-check">
              <input class="form-check-input mb-0" type="checkbox" v-model="dummyElement" id="defaultCheck2">
              <label class="form-check-label" for="defaultCheck2">Фиктивный элемент</label>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <button @click="impostsMType!=2 ? (setImposts=true,closeModal('imposts')) : editImposts()" class="btn btn-primary">ok</button>
      </template>
    </cModal>
    <cModal
      id="editProfil"
      :visible="activeModal === 'editProfil'"
      @close="closeModal"
      title="Вывод размера"
      _class="modal-md"
    >
      <template #default>
        <div class="container">
          <div class="row">
              <div class="col-12" style="text-align: right;">
                  <label class="form-check-label ml-2">
                    Фиктивный элемент
                    <input type="checkbox" class="ml-1" v-model="dummyElement">
                  </label>
              </div>
          </div>
          <div class="row">
              <div class="col-12">
                  <select class="form-select" v-model="selRama">
                      <option value="0">По умолчанию</option>
                      <option v-for="item in frames" :value="item.id" :key="item.id">{{item.articul}}</option>
                  </select>
              </div>
          </div>
          <div class="row mt-3" style="text-align: left;">
              <div class="col-6">
                  <h6>Тип соединения 1</h6>
                  <div class="form-check">
                      <input class="form-check-input" type="radio" v-model="tt" value="0" disabled>
                      <label class="form-check-label">по умолчанию</label>
                  </div>
                  <div class="form-check">
                      <input class="form-check-input" type="radio" v-model="tt" value="1" disabled>
                      <label class="form-check-label">45/45</label>
                  </div>
                  <div class="form-check">
                      <input class="form-check-input" type="radio" v-model="tt" value="2" disabled>
                      <label class="form-check-label">90/90 вертикальная цельная</label>
                  </div>
                  <div class="form-check">
                      <input class="form-check-input" type="radio" v-model="tt" value="4" disabled>
                      <label class="form-check-label">90/90 горизонтальная цельная</label>
                  </div>
                  <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="tt_" disabled>
                      <label class="form-check-label">шип / проушина</label>
                  </div>
              </div>
              <div class="col-6">
                  <h6>Тип соединения 2</h6>
                  <div class="form-check">
                      <input class="form-check-input" type="radio" v-model="dtt" value="0" disabled>
                      <label class="form-check-label">по умолчанию</label>
                  </div>
                  <div class="form-check">
                      <input class="form-check-input" type="radio" v-model="dtt" value="1" disabled>
                      <label class="form-check-label">45/45</label>
                  </div>
                  <div class="form-check">
                      <input class="form-check-input" type="radio" v-model="dtt" value="2" disabled>
                      <label class="form-check-label">90/90 вертикальная цельная</label>
                  </div>
                  <div class="form-check">
                      <input class="form-check-input" type="radio" v-model="dtt" value="4" disabled>
                      <label class="form-check-label">90/90 горизонтальная цельная</label>
                  </div>
                  <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="dtt_" disabled>
                      <label class="form-check-label">шип / проушина</label>
                  </div>
              </div>
          </div>

          <div class="row mt-3" style="text-align: left;border: 1px solid #ccc;padding-bottom: 10px;">
              <div class="col-12">
                  <h6 style="margin-top: -10px;">Соединители</h6>
                  <div class="row">
                      <div class="col-6">
                          <label>Фиксированная длина</label>
                          <input type="number" class="form-control" v-model="fixLength">
                      </div>
                      <div class="col-6">
                          <label>Добавить к размеру</label>
                          <input type="number" class="form-control" v-model="addToSize">
                      </div>
                  </div>

                  <div class="row mt-2">
                      <div class="col-6">
                        <select class="form-select" v-model="connectors.s1">
                          <option v-for="item in dividers" :value="item.id" :key="item.id">{{item.or1_name}}</option>
                        </select>
                      </div>
                      <div class="col-6">
                        <select class="form-select" v-model="connectors.s2">
                          <option v-for="item in dividers" :value="item.id" :key="item.id">{{item.or1_name}}</option>
                        </select>
                      </div>
                  </div>

                  <div class="row mt-2">
                      <div class="col-6">
                        <select class="form-select" v-model="connectors.s3">
                          <option v-for="item in dividers" :value="item.id" :key="item.id">{{item.or1_name}}</option>
                        </select>
                      </div>
                      <div class="col-6">
                        <select class="form-select" v-model="connectors.s4">
                          <option v-for="item in dividers" :value="item.id" :key="item.id">{{item.or1_name}}</option>
                        </select>
                      </div>
                  </div>
                  <div class="row mt-2">
                      <div class="col-6">
                        <select class="form-select" v-model="connectors.s15">
                          <option v-for="item in dividers" :value="item.id" :key="item.id">{{item.or1_name}}</option>
                        </select>
                      </div>
                      <div class="col-6">
                        <select class="form-select" v-model="connectors.s6">
                          <option v-for="item in dividers" :value="item.id" :key="item.id">{{item.or1_name}}</option>
                        </select>
                      </div>
                  </div>
                  <div class="row mt-2">
                      <div class="col-6">
                        <select class="form-select" v-model="connectors.s7">
                          <option v-for="item in dividers" :value="item.id" :key="item.id">{{item.or1_name}}</option>
                        </select>
                      </div>
                      <div class="col-6">
                        <select class="form-select" v-model="connectors.s8">
                          <option v-for="item in dividers" :value="item.id" :key="item.id">{{item.or1_name}}</option>
                        </select>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      </template>
      <template #footer>
        <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="setAll">
            <label class="form-check-label">Применить для всего контура</label>
        </div>
        <button  class="btn btn-primary" @click="setProfile">ok</button>
      </template>
    </cModal>
    </div>
  </template>
  <script >
  import { mapGetters, mapActions, mapMutations } from "vuex";
  
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';
  import cModal from './cModal.vue';
  import {getInsetPolygon} from "@/helpers/matf";
  import {getSelectionProf} from "@/helpers/frame";
  export default {
    name: "impostView",
    components: { cModal },
    data() {
        return {
          canvas: null,
          activeModal: null,
          boundingBox:[],
          scale:1,
          OFFSET:80,
          aRadius: null,
          arcHeight:null,
          frameLines:[],
          impostsLine:[],
          impostsMType: null,
          addImpostsNum: null,
          selImpostProf: null,
          dummyElement: null,
          addImpostsType: null,
          impostProfType: 1,
          connectors:{
            s1:null,
            s2:null,
            s3:null,
            s4:null,
            s5:null,
            s6:null,
            s7:null,
            s8:null,
          },
          fixLength:null,
          addToSize:null,
          dtt_:0,
          dtt:false,
          tt:0,
          tt_:false,
          selRama:0,
          setAll:false
        }
    },
    created() {
  
    },
    computed: {
      ...mapGetters("constr", ["addProfiles", "framePoints", "separators", "frameProfiles", "imposts"]),
      ...mapGetters("profile", ["profsys","articuls"]),
      frames() {
        return this.$store.getters['profile/articulTypes']([2]);
      },
      selFrame() {
        return this.$store.getters['profile/selected']("frame");
      },
      dividers() {
        return this.$store.getters['profile/articulTypes']([17]);
      },
      dImposts() {
        return this.$store.getters['profile/articulTypes']([6]);
      },
      dShtulp() {
        return this.$store.getters['profile/articulTypes']([14]);
      },
    },
    async mounted() {
      await this.getProfsys()
      await this.getConstrTypes()
      if(!this.articuls.length) {
        await this.$store.dispatch('profile/getArticuls');
      }
      this.canvas = new fabric.Canvas('constructor', {
          selection: false, 
          backgroundColor: '#ddd',
      });
      window.addEventListener('resize', this.resizeCanvas);
      await this.resizeCanvas();
      this.clearCanvas()
      this.updateCanvas()
      this.canvas.on('mouse:down', (e) => this.mouseDown(e));
      this.canvas.on('mouse:move', (e) => this.objectMoving(e));
      this.canvas.on('mouse:up', (e) => this.mouseUp(e));
    },
    methods: {
      ...mapActions("profile", ["getProfsys", "getConstrTypes"]),
      ...mapActions("constr", ["getaddProfiles"]),
      ...mapMutations("constr", ['updateStateItem', 'removeArrayItem', 'addFramePointinIndex','updateArrayItemProperty', 'addArrayItem']),
      async mountImpost(){
        const spr = this.$getScalePoinst(this.framePoints, this.canvas.width, this.canvas.height, this.OFFSET, this.separators)
        this.scale = spr.scale;
        this.updateStateItem({property:'framePoints',value:spr.points})
        this.updateStateItem({property:'separators',value:spr.separators})
        this.mx = spr.mx;
        this.left = spr.left;
        this.top = spr.top;
        return spr;
      },
      async resizeCanvas(e = false) {
        const container = this.$refs.canvasContainer;
        if(container){
          const containerWidth = Math.floor(container.clientWidth / 50) * 50;
          const containerHeight = Math.floor(container.clientHeight / 50) * 50;
          this.canvas.setWidth(containerWidth);
          this.canvas.setHeight(containerHeight);
          this.canvas.renderAll();
          await this.mountImpost();
          if(e){
            this.clearCanvas()
            this.updateCanvas()
          }
        }
      },
      sizeoutputFN(){
        switch (this.sizeoutputType) {
          case 'radius':
            this.setRadius()
            break;
          case 'arcHeight':
            this.setArcHeight()
            break;
          case 'editLength':
            this.editLength()
            break;  
          default:
            break;
        }
      },
      addImpost(type, num){
        this.addImpostsType = type
        this.addImpostsNum = num
        this.impostsMType = num == 4 ? 1 : null
        this.selImpostProf = this.impostProfType == 1 && this.dImposts.length ? this.dImposts[0].id : this.impostProfType !== 1 && this.dShtulp.length ? this.dShtulp[0].id : null
        this.dummyElement = false
        this.openModal('imposts')
      },
      editImposts(){
        this.closeModal('imposts')
        if(!this.selectedElement || this.selectedElement.ctype != 'imposts') return

        const index = this.selectedElement.lineIndex
        this.updateArrayItemProperty({property:'imposts', key:'prof', index, value: this.selImpostProf})
        this.updateArrayItemProperty({property:'imposts', key:'dummyElement', index, value: this.dummyElement})
        this.updateArrayItemProperty({property:'imposts', key:'profType', index, value: this.impostProfType})
        this.updateArrayItemProperty({property:'imposts', key:'drawLines', index, value: null})
        this.clearCanvas()
        this.updateCanvas()
      },
      impostsEdit(){
        if(!this.selectedElement || this.selectedElement.ctype != 'imposts') return

        const index = this.selectedElement.lineIndex;
        const impost = this.imposts[index];
        
        this.impostsMType = 2
        this.selImpostProf = impost.prof ? impost.prof : null
        this.dummyElement = impost.dummyElement||false
        this.impostProfType = impost.profType
        this.openModal('imposts')
      },
      editProfil(){
        if(!this.selectedElement || (this.selectedElement.ctype !== 'frameLine' && this.selectedElement.ctype !== 'separator')) return

        const index = this.selectedElement.lineIndex;
        const frame = this.selectedElement.ctype === 'separator' ? this.separators[index]?.frame||null : this.framePoints[index]?.frame||null;
        this.connectors = frame && frame.connectors ? frame.connectors : {s1:null,s2:null,s3:null,s4:null,s5:null,s6:null,s7:null,s8:null}
        this.fixLength = frame ? frame.fixLength : null
        this.addToSize = frame ? frame.addToSize : null
        this.dtt_ = frame ? frame.dtt_ : false
        this.dtt = frame ? frame.dtt : 0
        this.tt = frame ? frame.tt : 0
        this.tt_ = frame ? frame.tt_ : false
        this.selRama = frame ? frame.selRama : 0
        this.dummyElement = frame ? frame.dummyElement : false
        this.setAll = false
        this.openModal('editProfil')
      },
      setProfile(){
        this.closeModal('editProfil')
        if(!this.selectedElement || (this.selectedElement.ctype !== 'frameLine' && this.selectedElement.ctype !== 'separator')) return
        const index = this.selectedElement.lineIndex;
        const frame = {
          connectors: this.connectors,
          fixLength: this.fixLength,
          addToSize: this.addToSize,
          dtt_: this.dtt_,
          dtt: this.dtt,
          tt: this.tt,
          tt_: this.tt_,
          selRama: this.selRama,
          dummyElement: this.dummyElement
        }
        if (this.setAll) {
          for (let i = 0; i < this.framePoints.length; i++) {
            this.updateArrayItemProperty({property: 'framePoints', key: 'frame', index: i, value: frame})
          }
          for (let i = 0; i < this.separators.length; i++) {
            this.updateArrayItemProperty({property: 'separators', key: 'frame', index: i, value: frame})
          }
        } else {
          if(this.selectedElement.ctype === 'separator'){
            this.updateArrayItemProperty({property: 'separators', key: 'frame', index, value: frame})
          }else {
            this.updateArrayItemProperty({property: 'framePoints', key: 'frame', index, value: frame})
          }
        }
        this.clearCanvas()
        this.updateCanvas()
      },
      addImpostsLine(pointer){
        if(!this.setImposts || this.addImpostsNum < 2) return

        if(this.$isPointInsidePolygon(this.framePoints, pointer)){
          let line = { 
            ox1: this.getOrigPoinst(Math.round(pointer.x), 'x'),
            oy1: this.getOrigPoinst(Math.round(pointer.y), 'y'), 
            ox2: this.getOrigPoinst(Math.round(pointer.x), 'x'),
            oy2: this.getOrigPoinst(Math.round(pointer.y), 'y'),
          }
          let type = 'y';
          if(this.addImpostsType === 'H'){
            type = 'x'
            line.ox2 = line.ox2 + 10
          }else{
            line.oy2 = line.oy2 + 10
          }
          const p = type === 'x' ? pointer.x : pointer.y;
          let lines = [];
          this.frameProfiles.map(function(profile, i) {
            lines.push({
              x1: profile.p3.x,
              y1: profile.p3.y,
              x2: profile.p4.x,
              y2: profile.p4.y,
              r: profile.p3.r
            })
          })
          this.imposts.map(function(line, i) {
            if(line.drawLines){
              for (let i = 0; i < line.drawLines.length; i++) {
                lines.push(line.drawLines[i])
              }
            }
          })
          const twoLine = this.$lineLinesIntersections(line, lines);
          if(!twoLine.length) return;

          const {min, max} = this.$getMinMaxInTwoLine(twoLine, this.separators, p, type);
          // if(!min || !max) return;
          const step = ((max - min) / this.addImpostsNum).toFixed();
          for (let index = 1; index < this.addImpostsNum; index++) {
            if(this.addImpostsType == 'H'){
              line = { 
                ox1: min+(step*index),
                oy1: this.getOrigPoinst(Math.round(pointer.y), 'y'), 
                ox2: min+(step*index),
                oy2: this.getOrigPoinst(Math.round(pointer.y), 'y') + 10,
                prof: this.selImpostProf||null,
                profType: this.impostProfType,
                dummyElement: this.dummyElement||false
              }
            }else{
              line = { 
                ox1: this.getOrigPoinst(Math.round(pointer.x), 'x'),
                oy1: min+(step*index), 
                ox2: this.getOrigPoinst(Math.round(pointer.x), 'x')+10,
                oy2: min+(step*index),
                prof: this.selImpostProf||null,
                profType: this.impostProfType,
                dummyElement: this.dummyElement||false
              }
            }
            console.log(line)
            this.addArrayItem({property:'imposts', item:line})
          }
          this.clearCanvas()
          this.updateCanvas()
        }
        this.setImposts = false
      },
      addArcHeight(){
        if(!this.selectedElement || this.selectedElement.ctype == 'ivertex' || this.selectedElement.ctype == 'separators' || this.selectedElement.ctype == 'frameLine'){
          toast("Пожалуйста выберите линию!", {
            autoClose: 1000,
            type: "warning",
          });
          return;
        }
        const index = this.selectedElement.lineIndex;
        this.sizeoutput = null;
        const impost = this.imposts[index]
        if(impost.or){
          this.sizeoutput = this.cFixed(this.$calculateHeightByR(impost.ox1, impost.oy1, impost.ox2, impost.oy2, impost.or))
        }
        this.sizeoutputType = 'arcHeight'
        this.openModal('sizeoutput')
      },
      addRadius(){
        if(!this.selectedElement || this.selectedElement.ctype == 'ivertex' || this.selectedElement.ctype == 'separators' || this.selectedElement.ctype == 'frameLine'){
          toast("Пожалуйста выберите линию!", {
            autoClose: 1000,
            type: "warning",
          });
          return;
        }
        const index = this.selectedElement.lineIndex;
        this.sizeoutput = this.imposts[index].or||null;
        this.sizeoutputType = 'radius';
        this.openModal('sizeoutput')
      },
      setArcHeight(){
        if(!this.selectedElement || this.selectedElement.ctype == 'ivertex' || this.selectedElement.ctype == 'separators' || this.selectedElement.ctype == 'frameLine'){
          toast("Пожалуйста выберите линию!", {
            autoClose: 1000,
            type: "warning",
          });
          this.closeModal('sizeoutput')
          return;
        }
        const index = this.selectedElement.lineIndex;
        var radius = null;
        if(this.sizeoutput && this.sizeoutput != 0){
          const impost = this.imposts[index]
          radius = this.$calculateRadiusByH(impost.ox1, impost.oy1, impost.ox2, impost.oy2, this.sizeoutput)
          const dx = impost.ox2 - impost.ox1;
          const dy = impost.oy2 - impost.oy1;
          const distance = Math.sqrt(dx * dx + dy * dy);
          const minRadius = distance / 2;
          if (Math.abs(radius) < minRadius) {
            radius = radius < 0 ? minRadius * -1 : minRadius;
            this.sizeoutput = this.cFixed(this.$calculateHeightByR(impost.ox1, impost.oy1, impost.ox2, impost.oy2, radius))
            toast("Всота дуги не может быть меньше "+this.sizeoutput, {
              autoClose: 1000,
              type: "warning",
            });
            return;
          }
        }
        this.closeModal('sizeoutput')
        this.updateArrayItemProperty({property:'imposts', key:'or', index, value: radius})
        this.clearCanvas()
        this.updateCanvas()
      },
      setRadius(){
        if(!this.selectedElement || this.selectedElement.ctype == 'ivertex' || this.selectedElement.ctype == 'separators' || this.selectedElement.ctype == 'frameLine'){
          toast("Пожалуйста выберите линию!", {
            autoClose: 1000,
            type: "warning",
          });
          this.closeModal('sizeoutput')
          return;
        }
        const index = this.selectedElement.lineIndex;
        if(this.sizeoutput && this.sizeoutput != 0){
          const impost = this.imposts[index]
          const dx = impost.ox2 - impost.ox1;
          const dy = impost.oy2 - impost.oy1;
          const distance = Math.sqrt(dx * dx + dy * dy);
          const minRadius = distance / 2;
          if (Math.abs(this.sizeoutput) < minRadius) {
            this.sizeoutput = this.sizeoutput < 0 ? minRadius * -1 : minRadius;
            toast("Радус не может быть меньше "+this.sizeoutput, {
              autoClose: 1000,
              type: "warning",
            });
            return;
          }
        }
        this.closeModal('sizeoutput')
        this.updateArrayItemProperty({property:'imposts', key:'or', index, value: this.sizeoutput||null})
        this.clearCanvas()
        this.updateCanvas()
      },
      drawFrame(){
        this.frameLines = []
        for (var i = 0; i < this.frameProfiles.length; i++) {
          if(!this.frameProfiles[i].p1 || !this.frameProfiles[i].p2) continue;
          const p1 = this.getRendPoints(this.frameProfiles[i].p1)
          const p2 = this.getRendPoints(this.frameProfiles[i].p2)
          const p3 = this.getRendPoints(this.frameProfiles[i].p3)
          const p4 = this.getRendPoints(this.frameProfiles[i].p4)
          let pathData;
          if(p1.r){
            const t = this.frameProfiles[i].p1.r < 0 ? 0 : 1;
            pathData = `
              M ${p1.x} ${p1.y}
              A ${p1.r} ${p1.r} 0 0 ${t} ${p2.x} ${p2.y}
              L ${p4.x} ${p4.y}
              A ${p3.r} ${p3.r} 0 0 ${t===1?0:1} ${p3.x} ${p3.y}
              Z
            `;
          }else{
            pathData = `
              M ${p1.x} ${p1.y}
              L ${p2.x} ${p2.y}
              L ${p4.x} ${p4.y}
              L ${p3.x} ${p3.y}
              Z
            `;
          }
          const path = new fabric.Path(pathData, {
            stroke: 'black',
            strokeWidth: 1,
            selectable: false,
            fill: 'white', // Фон текста
            lineIndex: p1.index,
            ctype: p1.type
          });
          this.canvas.add(path)
          this.frameLines.push(path)
          let textelem;
          const frame = p1?.frame
          if(frame && frame.selRama){
            const prof = this.frames.find(obj => obj.id === frame.selRama);
            if(!prof) continue
            
            if(p1.r){
              const coords = this.$getArcCoords(p1, p2);
              textelem = this.$placeTextAlongArc(prof.articul.toString(), coords.left+coords.radius, coords.top+coords.radius, coords.radius, coords.startAngle, coords.endAngle)
            }else{
              const angle = Math.atan2(p2.y - p1.y, p2.x - p1.x);
              textelem = new fabric.Text(prof.articul.toString(), {
                  left: (p2.x + p1.x)/2,
                  top: (p2.y + p1.y)/2,
                  fontSize: 14, // Размер текста
                  fill: 'red', // Цвет текста
                  originX: 'center',
                  originY: 'center',
                  backgroundColor: 'white', // Фон текста
                  angle: angle * (180 / Math.PI), // Учет уклона линии
                  selectable: false, // Разрешить выбор объекта
                  evented: true // Включить обработку событий
              });
            }
            this.canvas.add(textelem)
          }
        }
        this.drawConnectors()
      },
      drawConnectors(){
        for(let i = 0; i < this.framePoints.length; i++){
          let connector;
          const nextIndex = (i + 1) % this.framePoints.length;
          if(this.framePoints[i]?.frame && this.framePoints[i].frame.connectors && (this.framePoints[i].frame.connectors.s1 || this.framePoints[i].frame.connectors.s2 || this.framePoints[i].frame.connectors.s3 || this.framePoints[i].frame.connectors.s4 || this.framePoints[i].frame.connectors.s5 ||this.framePoints[i].frame.connectors.s6 || this.framePoints[i].frame.connectors.s7 || this.framePoints[i].frame.connectors.s8)){
            if(this.framePoints[i].r){
              const coords = this.$getArcCoords(this.framePoints[i], this.framePoints[nextIndex]);
              connector = new fabric.Circle({
                left: coords.left,
                top: coords.top,
                radius: coords.radius,
                startAngle: coords.startAngle,
                endAngle: coords.endAngle,
                stroke: 'black',
                strokeWidth: 2,
                fill: '',
                angle: 0,
                selectable: false,
                lineIndex: i
              });
            }else{
              connector = new fabric.Line([this.framePoints[i].x, this.framePoints[i].y, this.framePoints[nextIndex].x, this.framePoints[nextIndex].y], {
                stroke: 'black', strokeWidth: 2, hasControls: false, selectable: false
              });
            }
            this.canvas.add(connector)
          }
        }
      },
      clearCanvas(){
        this.selectedElement = null
        this.boundingBox = []
        this.canvas.clear();
        this.canvas.setBackgroundColor('#ddd');
      },
      updateCanvas(){
        this.updatePoints()
        this.drawFrame()
        this.renderSeparators()
        this.renderImposts()
        this.drawBoundingBox()
      },
      updatePoints(){
        this.$store.dispatch('constr/updateSeparators');
        this.$store.dispatch('constr/frameProfiles');
        this.$store.dispatch('constr/updateImposts');
      },
      mouseUp(e){
        this.isMouseDown = false
      },
      mouseDown(e){
        this.isMouseDown = true
        var pointer = this.canvas.getPointer(e.e);
        var target = null;

        for (var s = 0; s < this.impostsLine.length; s++) {
          if (this.$isCornersElement(pointer, this.impostsLine[s].circle1)) {
            target = this.impostsLine[s].circle1;
            break;
          }
          if (this.$isCornersElement(pointer, this.impostsLine[s].circle2)) {
            target = this.impostsLine[s].circle2;
            break;
          }
        }
        if(!target){
          for (var sl = 0; sl < this.impostsLine.length; sl++) {
            const index = this.impostsLine[sl].circle1.lineIndex
            const line = this.imposts[index];
            const prof = line.prof ? line.profType == 1 ? this.dImposts.find(obj => obj.id === line.prof) : this.dShtulp.find(obj => obj.id === line.prof) : null;
            let distance = prof ? prof.wr : 40;
            distance = distance > 5 ? distance*this.scale : 6;
            if(line.or){
              if(this.$isPointOnArc({x:this.getRendPoint(line.ox1, 'x'),y:this.getRendPoint(line.oy1, 'y'),or:line.or, r: Math.ceil(Math.abs(line.or) * this.scale * 1000) / 1000}, {x:this.getRendPoint(line.ox2, 'x'),y:this.getRendPoint(line.oy2, 'y')}, pointer.x, pointer.y, distance)){
                target = this.impostsLine[sl].lines[0];
                break;
              }
            }else{
              if (this.$isPointOnLine(
                this.impostsLine[sl].circle1.left+this.impostsLine[sl].circle1.radius, 
                this.impostsLine[sl].circle1.top+this.impostsLine[sl].circle1.radius,  
                this.impostsLine[sl].circle2.left+this.impostsLine[sl].circle2.radius,
                this.impostsLine[sl].circle2.top+this.impostsLine[sl].circle2.radius,  
                pointer.x, pointer.y, distance/2
              )) {
                target = this.impostsLine[sl].lines[0];
                break;
              }
            }
          }
        }
        if(!target){
          for (let i = 0; i < this.frameLines.length; i++) {
              const points = [
                this.getRendPoints(this.frameProfiles[i].p1),
                this.getRendPoints(this.frameProfiles[i].p2),
                this.getRendPoints(this.frameProfiles[i].p4),
                this.getRendPoints(this.frameProfiles[i].p3),
              ]
              if(points[0].r){
                const prof = getSelectionProf(this.frames, points[0]?.frame, this.selFrame)
                const wr = prof?.wr||20
                if(this.$isPointInArcBand(points[0], points[1], points[0].r, wr*this.scale, pointer)) {
                  target = this.frameLines[i];
                  break;
                }
              }else if(this.$isPointInsidePolygon(points, pointer)){
                  target = this.frameLines[i];
                  break;
              }
          }
          if(target){
            const currentTime = new Date().getTime();
            const timeSinceLastClick = currentTime - this.lastClickTime;
            this.lastClickTime = currentTime;
            if (timeSinceLastClick < 300) {
              return this.editProfil()
            }
          }
        }
        if(target && target.ctype == 'imposts'){
          const currentTime = new Date().getTime();
          const timeSinceLastClick = currentTime - this.lastClickTime;
          if (timeSinceLastClick < 300) {
            return this.impostsEdit()
          }
          this.lastClickTime = currentTime;
        }
        if (target) {
          this.unSelectElement()
          this.selectedElement = target;
          if(this.selectedElement.ctype == 'vertex' || this.selectedElement.ctype == 'ivertex'){
              this.selectedElement.set({ fill: 'red' });
              // Создаем горизонтальную линию
              var hc = [this.minMax.minX-20, this.selectedElement.top+5, this.minMax.maxX, this.selectedElement.top+5]
              var vc = [this.selectedElement.left+5, this.minMax.minY-20, this.selectedElement.left+5, this.minMax.maxY]
              if(this.selectedElement.ctype == 'ivertex'){
                hc = [this.minMax.minX, this.selectedElement.top+5, this.minMax.maxX+20, this.selectedElement.top+5]
                vc = [this.selectedElement.left+5, this.minMax.minY, this.selectedElement.left+5, this.minMax.maxY+20]
              }
              this.horizontalLine = new fabric.Line(hc, {
                  stroke: 'red',
                  strokeDashArray: [5, 5],
                  selectable: false,
                  evented: false,
                  // visible: false
              });

              // Создаем вертикальную линию
              this.verticalLine = new fabric.Line(vc, {
                  stroke: 'red',
                  strokeDashArray: [5, 5],
                  selectable: false,
                  evented: false,
                  // visible: false
              });
              this.canvas.add(this.horizontalLine, this.verticalLine);
          }else{
            if(this.selectedElement.ctype == 'imposts'){
              const index = this.selectedElement.lineIndex
              for (let i = 0; i < this.impostsLine[index].lines.length; i++) {
                this.impostsLine[index].lines[i].set({ stroke: 'blue' });
              }
            }else{
              this.selectedElement.set({ stroke: 'blue' });
            }
          }
          this.canvas.renderAll();
        }else{
          this.addImpostsLine(pointer)
        }
      },
      objectMoving(e){
        if (!this.selectedElement || !this.isMouseDown) return;

        const pointer = this.canvas.getPointer(e.e);
        let x = Math.round(pointer.x);
        let y = Math.round(pointer.y);
        if (this.selectedElement.type === 'circle' && this.selectedElement.ctype == 'ivertex') {
            const index = this.selectedElement.lineIndex;
            var k = this.selectedElement.pointIndex;
            this.updateArrayItemProperty({property:'imposts', key:'ox'+k, index, value: Math.round(x - this.getRendPoint(this.imposts[index]['ox'+k], 'x')) + this.imposts[index]['ox'+k]})
            this.updateArrayItemProperty({property:'imposts', key:'oy'+k, index, value: Math.round(y - this.getRendPoint(this.imposts[index]['oy'+k], 'y')) + this.imposts[index]['oy'+k]})

            if (Math.abs(this.imposts[index].ox1 - this.imposts[index].ox2) < 20) {
              if(k==1){
                this.updateArrayItemProperty({property:'imposts', key:'ox1', index, value: this.imposts[index].ox2})
              }else{
                this.updateArrayItemProperty({property:'imposts', key:'ox2', index, value: this.imposts[index].ox1})
              }
            }
            if (Math.abs(this.imposts[index].oy1 - this.imposts[index].oy2) < 20) {
              if(k==1){
                this.updateArrayItemProperty({property:'imposts', key:'oy1', index, value: this.imposts[index].oy2})
              }else{
                this.updateArrayItemProperty({property:'imposts', key:'oy2', index, value: this.imposts[index].oy1})
              }
            }
          this.$store.dispatch('constr/updateImposts');
            // console.log(this.imposts)
            // Обновляем элементы
            this.drawBoundingBox();
            this.updateImpostsLine()
            this.updateVHLines();
            this.canvas.renderAll();
        }
      },
      updateImpostsLine(){
        this.impostsLine.forEach((line, index) => {
          this.canvas.remove(this.impostsLine[index].lines[0])
          if(this.impostsLine[index].lines[1]){
            this.canvas.remove(this.impostsLine[index].lines[1])
          }
          this.canvas.remove(this.impostsLine[index].circle1)
          this.canvas.remove(this.impostsLine[index].circle2)
          this.canvas.remove(this.impostsLine[index].textElem)
        });
        this.impostsLine = []
        this.renderImposts(true)
        if (this.selectedElement){
          const index = this.selectedElement.lineIndex
          if (this.selectedElement.ctype == 'imposts') {
            for (let i = 0; i < this.impostsLine[index].lines.length; i++) {
              this.impostsLine[index].lines[i].set({ stroke: 'blue' });
            }
          }else{
              this.selectedElement.set({ stroke: 'blue' });
          }
          if (this.selectedElement.ctype == 'ivertex') {
            this.impostsLine[index]['circle'+this.selectedElement.pointIndex].set({ fill: 'red' });
          }
        }
      },
      updateVHLines(){
        if(!this.selectedElement) return;

        if(this.selectedElement.ctype == 'ivertex'){
          if(this.verticalLine){
            this.verticalLine.set({ x1: this.selectedElement.left+5, y1: this.minMax.minY, x2: this.selectedElement.left+5, y2: this.minMax.maxY+20 });
          }

          if(this.horizontalLine){
            this.horizontalLine.set({ x1: this.minMax.minX, y1: this.selectedElement.top+5, x2: this.minMax.maxX+20, y2: this.selectedElement.top+5 });
          }
        }else{
          if(this.verticalLine){
            this.verticalLine.set({ x1: this.selectedElement.left+5, y1: this.minMax.minY-20, x2: this.selectedElement.left+5, y2: this.minMax.maxY });
          }

          if(this.horizontalLine){
            this.horizontalLine.set({ x1: this.minMax.minX-20, y1: this.selectedElement.top+5, x2: this.minMax.maxX, y2: this.selectedElement.top+5 });
          }
        }
      },
      unSelectElement(){
        if (this.selectedElement) {
            if(this.selectedElement.ctype == 'ivertex'){
                this.selectedElement.set({ fill: 'black' });
            }else{
              this.selectedElement.set({ stroke: 'black' });
            }
            if (this.selectedElement.ctype == 'imposts') {
              const index = this.selectedElement.lineIndex
              for (let i = 0; i < this.impostsLine[index].lines.length; i++) {
                this.impostsLine[index].lines[i].set({ stroke: '#4d4d4d' });
              }
              this.impostsLine[index].textElem.set({ fill: 'red' });
            }
            if(this.horizontalLine){
              this.canvas.remove(this.horizontalLine, this.verticalLine);
              this.horizontalLine=this.verticalLine = null
            }
        }
      },
      renderImposts(type){
        var _self = this;
        this.impostsLine = []
        console.log(this.imposts)
        this.imposts.map(function(point, index) {
          const prof = point.prof ? point.profType == 1 ? _self.dImposts.find(obj => obj.id === point.prof) : _self.dShtulp.find(obj => obj.id === point.prof) : null;
          const x1 = _self.getRendPoint(point.ox1, 'x')
          const x2 = _self.getRendPoint(point.ox2, 'x')
          const y1 = _self.getRendPoint(point.oy1, 'y')
          const y2 = _self.getRendPoint(point.oy2, 'y')
          var aCoords = point.or ? _self.$getArcCoords(
                {x:x1,y:y1,or:point.or, r: Math.ceil(Math.abs(point.or) * _self.scale * 1000) / 1000},
                {x:x2,y:y2}) : null;
          var circle1 = new fabric.Circle({
              left: x1 - 5,
              top: y1 - 5,
              radius: 5,
              fill: '#4d4d4d',
              strokeWidth: 1,
              hasControls: false,
              hasBorders: false,
              selectable: false,
              ctype:'ivertex',
              lineIndex: index,
              pointIndex: 1
          });
          var circle2 = new fabric.Circle({
              left: x2 - 5,
              top: y2 - 5,
              radius: 5,
              fill: '#4d4d4d',
              strokeWidth: 1,
              hasControls: false,
              hasBorders: false,
              selectable: false,
              ctype:'ivertex',
              lineIndex: index,
              pointIndex: 2
          });
          const angle = _self.$findAngleBetweenPoints(x1, y1, x2, y2)
          var text = angle.toFixed(1).toString()+'°'
          var line;  
          var lines = [];
          if(point.drawLines && point.drawLines.length){
            let drawLines = point.drawLines
            for (let i = drawLines.length - 1; i >= 0; i--) {
              const replace = _self.$getDistance(x1, y1, drawLines[i].x1, drawLines[i].y1) < _self.$getDistance(x1, y1, drawLines[i].x2, drawLines[i].y2);
              const dline = {
                x1: replace ? drawLines[i].x1 : drawLines[i].x2,
                y1: replace ? drawLines[i].y1 : drawLines[i].y2,
                x2: replace ? drawLines[i].x2 : drawLines[i].x1,
                y2: replace ? drawLines[i].y2 : drawLines[i].y1
              }
              if (point.or) {
                  // Создаем дугу
                  var coords = _self.$getArcCoords(
                    {x:_self.getRendPoint(dline.x1, 'x'),y:_self.getRendPoint(dline.y1, 'y'),or:point.or, r: Math.ceil(Math.abs(point.or) * _self.scale * 1000) / 1000},
                    {x:_self.getRendPoint(dline.x2, 'x'),y:_self.getRendPoint(dline.y2, 'y')});
                  line = new fabric.Circle({
                      left: coords.left,
                      top: coords.top,
                      radius: coords.radius,
                      startAngle: coords.startAngle,
                      endAngle: coords.endAngle,
                      stroke: 'black',
                      strokeWidth: 3,
                      fill: '',
                      angle: 0,
                      selectable: false,
                      lineIndex: index,
                      ctype: 'imposts'
                  });
              } else {
                line = new fabric.Line([_self.getRendPoint(dline.x1, 'x'), _self.getRendPoint(dline.y1, 'y'), _self.getRendPoint(dline.x2, 'x'), _self.getRendPoint(dline.y2, 'y')], {
                    stroke: 'black',
                    strokeWidth: 2,
                    hasControls: false,
                    selectable: false,
                    lineIndex: index,
                    ctype: 'imposts'
                });
              }
              _self.canvas.add(line) 
              lines.push(line)
            } 
          }else{
            if (point.or) {
              // Создаем дугу
              line = new fabric.Circle({
                  left: aCoords.left,
                  top: aCoords.top,
                  radius: aCoords.radius,
                  startAngle: aCoords.startAngle,
                  endAngle: aCoords.endAngle,
                  stroke: 'black',
                  strokeWidth: 3,
                  fill: '',
                  angle: 0,
                  selectable: false,
                  lineIndex: index,
                  ctype: 'imposts'
              });
            } else {
              line = new fabric.Line([x1, y1, x2, y2], {
                    stroke: 'black',
                    strokeWidth: 3,
                    hasControls: false,
                    selectable: false,
                    lineIndex: index,
                    ctype: 'imposts'
                });
            }
            lines.push(line)
            _self.canvas.add(line) 
          }
          if(prof){
            text = ' '+text+'  '+prof.or1_name.toString()
          }
          var textElem;
          if (point.or) {
            textElem = _self.$placeTextAlongArc(text, aCoords.left+aCoords.radius, aCoords.top+aCoords.radius, aCoords.radius, aCoords.startAngle, aCoords.endAngle)
          }else{  
            textElem = new fabric.Text(text, {
              left: (x1 + x2) / 2,
              top: (y1 + y2) / 2,
              fontSize: 10, // Размер текста
              fill: 'black', // Цвет текста
              backgroundColor: 'white', // Фон текста
              originX: 'center',
              originY: 'center',
              angle: angle, // Учет уклона линии
              selectable: false, // Разрешить выбор объекта
              evented: false // Включить обработку событий
            });
          }
          if(type && _self.selectedElement && _self.selectedElement.lineIndex == index){
            if(_self.selectedElement.ctype == 'ivertex'){
              _self.selectedElement = _self.selectedElement.pointIndex == 1 ? circle1 : circle2;
            }
            if(_self.selectedElement.ctype == 'imposts'){
              _self.selectedElement = lines[0];
            }
          }
          _self.canvas.add(textElem, circle1,circle2)
          _self.impostsLine.push({lines,circle1,circle2,textElem})
        });
      },
      renderSeparators(type){
        var _self = this;
        this.separatorsLine=[];
        this.separators.map(function(point, index) {
          let line;
          if(point.drawLines && point.drawLines.length){
              let _points = []
              let drawLines = point.drawLines
              const normalizPoint = _self.$normalizeLine({
                  x1:point.x1,
                  y1:point.y1,
                  x2:point.x2,
                  y2:point.y2
                })
              var pp;
              for (let i = 0; i < drawLines.length; i++) {
                if(i*2 === drawLines.length){
                  _points.push({x:normalizPoint.x1,y:normalizPoint.y1});
                }
                pp = {x:_self.getRendPoint(drawLines[i].x1, 'x'),y:_self.getRendPoint(drawLines[i].y1, 'y')};
                _points.push(pp)
              }
              for (let i = drawLines.length - 1; i >= 0; i--) {
                pp = {x:_self.getRendPoint(drawLines[i].x2, 'x'),y:_self.getRendPoint(drawLines[i].y2, 'y')};
                _points.push(pp)
                if(i*2 === drawLines.length){
                  _points.push({x:normalizPoint.x2,y:normalizPoint.y2});
                }
              }
              const color = point.dummyElement ? 'red' : 'grey';
              line = _self.$drawPolygon(_points, 'separators', index, color, '#dddddd');
          }else{
            line = new fabric.Line([point.x1, point.y1, point.x2, point.y2], {
                  // stroke: '#cccccc',
                  // strokeWidth: 1,
                  hasControls: false,
                  selectable: false,
                  lineIndex: index,
                  ctype: 'separators'
              });
          }
          _self.canvas.add(line)
          _self.separatorsLine.push(line)
        });
      },
      drawBoundingBox() {
        // Удаляем предыдущий ограничивающий прямоугольник, если он существует
        if (this.boundingBox.length) {
          this.boundingBox.forEach(element => {
            this.canvas.remove(element);
          });
          this.boundingBox=[];
        }
  
        // Находим минимальные и максимальные координаты всех объектов с учетом радиусов
        const {minX, minY, maxX, maxY} = this.$minMaxCords(this.framePoints);
        this.minMax = {minX, minY, maxX, maxY};
        var uniqueX = {},uniqueY = {};
        this.framePoints.forEach(point => {
          uniqueX['k'+point.ox] = point.x
          uniqueY['k'+point.oy] = point.y
        });
        uniqueX['k'+this.mx.minX] = this.minMax.minX
        uniqueX['k'+this.mx.maxX] = this.minMax.maxX
        uniqueY['k'+this.mx.minY] = this.minMax.minY
        uniqueY['k'+this.mx.maxY] = this.minMax.maxY
        uniqueX = Object.entries(uniqueX).sort((a, b) => { return Number(a[1]) - Number(b[1]);});
        uniqueY = Object.entries(uniqueY).sort((a, b) => { return Number(a[1]) - Number(b[1]);});
        
        this.createArrowWithLength(minY, maxY, minX, 60, Math.abs(this.mx.minY - this.mx.maxY), 'y', 1);
        this.createArrowWithLength(minX, maxX,  minY, 60, Math.abs(this.mx.minX - this.mx.maxX), 'x', 1);
        var length;
        if(uniqueY.length > 2){
          for (let iy = 0; iy < uniqueY.length; iy++) {
            length = Math.abs(parseFloat(uniqueY[iy][0].slice(1)) - parseFloat(uniqueY[iy+1][0].slice(1)));
            this.createArrowWithLength(uniqueY[iy][1], uniqueY[iy+1][1], minX, 40, length, 'y');
            if(iy+2 == uniqueY.length) break;
          }
        }
        if(uniqueX.length > 2){
          for (let ix = 0; ix < uniqueX.length; ix++) {
            length = Math.abs(parseFloat(uniqueX[ix][0].slice(1)) - parseFloat(uniqueX[ix+1][0].slice(1)));
            this.createArrowWithLength(uniqueX[ix][1], uniqueX[ix+1][1], minY, 40, length, 'x');
            if(ix+2 == uniqueX.length) break;
          }
        }
  
        var uniquesX = {},uniquesY = {};
        this.separators.forEach(point => {
          uniquesX['k'+Math.trunc(point.ox1)] = point.x1
          uniquesX['k'+Math.trunc(point.ox2)] = point.x2
          uniquesY['k'+Math.trunc(point.oy1)] = point.y1
          uniquesY['k'+Math.trunc(point.oy2)] = point.y2
        });
        uniquesX['k'+Math.trunc(this.mx.minX)] = this.minMax.minX
        uniquesX['k'+Math.trunc(this.mx.maxX)] = this.minMax.maxX
        uniquesY['k'+Math.trunc(this.mx.minY)] = this.minMax.minY
        uniquesY['k'+Math.trunc(this.mx.maxY)] = this.minMax.maxY
        uniquesX = Object.entries(uniquesX).sort((a, b) => { return Number(a[1]) - Number(b[1]);});
        uniquesY = Object.entries(uniquesY).sort((a, b) => { return Number(a[1]) - Number(b[1]);});
        if(uniquesX.length > 2){
          for (let sx = 0; sx < uniquesX.length; sx++) {
            length = Math.abs(parseFloat(uniquesX[sx][0].slice(1)) - parseFloat(uniquesX[sx+1][0].slice(1)));
            this.createArrowWithLength(uniquesX[sx][1], uniquesX[sx+1][1], maxY, -40, length, 'x', 3);
            if(sx+2 == uniquesX.length) break;
          }
        }
        if(uniquesY.length > 2){
          for (let sy = 0; sy < uniquesY.length; sy++) {
            length = Math.abs(parseFloat(uniquesY[sy][0].slice(1)) - parseFloat(uniquesY[sy+1][0].slice(1)));
            this.createArrowWithLength(uniquesY[sy][1], uniquesY[sy+1][1], maxX, -40, length, 'y', 3);
            if(sy+2 == uniquesY.length) break;
          }
        }
        
      },
      createArrowWithLength(from, to, min, d, length, type, all=2) {
          length = parseFloat(length.toFixed(1))
          const fromX = type == 'x' ? from : min-d;
          const fromY = type == 'y' ? from : min-d;
          const toX = type == 'x' ? to : min-d;
          const toY = type == 'y' ? to : min-d;
  
          var angle = Math.atan2(toY - fromY, toX - fromX);
          var headLength = 10; // Длина стрелки
  
          // Координаты стрелок на концах
          var arrowX1 = toX - headLength * Math.cos(angle - Math.PI / 6);
          var arrowY1 = toY - headLength * Math.sin(angle - Math.PI / 6);
          var arrowX2 = toX - headLength * Math.cos(angle + Math.PI / 6);
          var arrowY2 = toY - headLength * Math.sin(angle + Math.PI / 6);
  
          var arrowX3 = fromX + headLength * Math.cos(angle - Math.PI / 6);
          var arrowY3 = fromY + headLength * Math.sin(angle - Math.PI / 6);
          var arrowX4 = fromX + headLength * Math.cos(angle + Math.PI / 6);
          var arrowY4 = fromY + headLength * Math.sin(angle + Math.PI / 6);
  
          var line = new fabric.Line([fromX, fromY, toX, toY], {
              fill: 'black',
              stroke: 'black',
              strokeWidth: 1,
              selectable: false // Невозможно выбрать объект
          });
  
          var arrowHead1 = new fabric.Polygon([
              { x: toX, y: toY },
              { x: arrowX1, y: arrowY1 },
              { x: arrowX2, y: arrowY2 }
          ], {
              fill: 'black',
              selectable: false // Невозможно выбрать объект
          });
  
          var arrowHead2 = new fabric.Polygon([
              { x: fromX, y: fromY },
              { x: arrowX3, y: arrowY3 },
              { x: arrowX4, y: arrowY4 }
          ], {
              fill: 'black',
              selectable: false // Невозможно выбрать объект
          });
  
          const lline =  new fabric.Group([line, arrowHead1, arrowHead2], {
              selectable: false, // Невозможно выбрать объект
              hasControls: false
          });
  
          // Создаем текстовый элемент с фоном
          var text = new fabric.Text(length.toString(), {
              left: (fromX + toX) / 2,
              top: (fromY + toY) / 2,
              fontSize: 12, // Размер текста
              fill: 'red', // Цвет текста
              backgroundColor: 'white', // Фон текста
              originX: 'center',
              originY: 'center',
              angle: angle * 180 / Math.PI, // Учет уклона линии
              selectable: false, // Разрешить выбор объекта
              evented: true // Включить обработку событий
          });
          text.on('mousedown', (e) => this.onTextClick(e, to, from, type, all, length));
  
          this.boundingBox.push(lline,text)
          this.canvas.add(lline,text);
      },
      removeImposts(type){
        if(type == 'all'){
          this.updateStateItem({property:'imposts',value:[]})
          this.impostsLine = []
        }else{
          if(!this.selectedElement || this.selectedElement.ctype != 'imposts'){
            toast("Пожалуйста выберите линю!", {
              autoClose: 1000,
              type: "warning",
            });
            return;
          }
          const index = this.selectedElement.lineIndex;
          this.removeArrayItem({property:'imposts',index})
        }
        this.clearCanvas()
        this.updateCanvas()
      },
      onTextClick(e, to, from, type, all, length){
        this.editLengthData = {e, to, from, type, all, len:length}
        this.sizeoutputType = 'editLength'
        this.sizeoutput = length;
        this.openModal('sizeoutput')
      },
      onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
      },
      openModal(id) {
        this.activeModal = id;
      },
      closeModal(id) {
        if (this.activeModal === id) {
          this.activeModal = null;
        }
      },
      addUniqueNumber(arr, num) {
          // Проверка, есть ли в массиве число, которое на 1 больше или меньше
          const exists = arr.some(item => Math.abs(item - num) <= 1);
  
          // Если такого числа нет, добавляем новое число в массив
          if (!exists) {
              arr.push(num);
          }
          
          return arr;
      },
      cFixed(num, decimals=2) {
          // Проверяем, является ли число целым
          if (Number.isInteger(num)) {
              return num; // Для целых чисел возвращаем число без изменений
          }
          
          // Если число дробное, используем toFixed для округления
          return num.toFixed(decimals);
      },
      getOrigPoinst(val, type){
        if(type == 'x'){
          return (val - this.OFFSET - this.left) / this.scale + this.mx.minX
        }
        if(type == 'y'){
          return (val - this.OFFSET - this.top) / this.scale + this.mx.minY
        }
      },
      getRendPoint(val, type){
        if(type === 'x'){
          return (val - this.mx.minX) * this.scale + this.OFFSET + this.left
        }
        if(type === 'y'){
          return (val - this.mx.minY) * this.scale + this.OFFSET + this.top
        }
      },
      getRendPoints(point){
        return {
          ...point,
          x: this.getRendPoint(point.x, 'x'),
          y: this.getRendPoint(point.y, 'y'),
          r: point.r ? Math.ceil(Math.abs(point.r) * this.scale * 1000) / 1000 : 0,
          or: point.or||point.r||0
        }
      },
      getRoundedSteps(distance, d) {
          let steps = Math.floor(distance / d);
          if (steps % 2 !== 0) {
              steps--; // Если количество шагов нечетное, уменьшаем на 1
          }
          return Math.max(steps, 2);
      },
      getAllLines(k='',k1=''){
        var lines = []
        this.framePoints.forEach((point, index) => {
          var nextIndex = (index + 1) % this.framePoints.length;
          lines.push({
            x1:this.framePoints[index][k+'x'+k1],
            y1:this.framePoints[index][k+'y'+k1],
            x2:this.framePoints[nextIndex][k+'x'+k1],
            y2:this.framePoints[nextIndex][k+'y'+k1],
            r:this.framePoints[index][k+'r'+k1]
          })
        })
        return lines;
      }
  
    }
  }
  </script>
<style scoped>
   .form-control, .form-select{
    padding: 2px!important;
   }
</style>