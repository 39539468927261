import {findPointOnArc, shiftLineInTriangle, shiftLineClosestToPoint} from "@/helpers/matf";
import {calculateCentroid} from "@/helpers/splitPolygons";

export function getLigamentTyp(prof1, prof2, line1){
    const connections1 = availableConnections(parseInt(prof1.tt));
    const connections2 = availableConnections(parseInt(prof2.tt));
    const defualtConnection1 = parseInt(prof1.dtt) > 8 ? parseInt(prof1.dtt) - 8 : parseInt(prof1.dtt)||1;
    const defualtConnection2 = parseInt(prof2.dtt) > 8 ? parseInt(prof2.dtt) - 8 : parseInt(prof2.dtt)||1;
    const Line1Class = classifyLine(line1);
    const Line2Class = Line1Class === 2 ? 4 : 2;
    if(defualtConnection1 === 1 && connections2.includes(1)){
        return 1;
    }
    if([2, 4].includes(defualtConnection1) && (connections2.includes(2) || connections2.includes(4))){
        return Line1Class === defualtConnection1 ? 2 : 3;
    }
    if(defualtConnection2 === 1 && connections1.includes(1)){
        return 1;
    }
    if([2, 4].includes(defualtConnection2) && (connections1.includes(2) || connections1.includes(4))){
        return Line2Class === defualtConnection2 ? 3 : 2;
    }
    return 1;
}

function availableConnections(stt) {
    const values = [8, 4, 2, 1]; // Возможные значения
    const tt = [];

    for (const value of values) {
        if (stt >= value) {
            tt.push(value);
            stt -= value;
        }
    }

    return tt;
}

function classifyLine(line) {
    const dx = Math.abs(line.x2 - line.x1);
    const dy = Math.abs(line.y2 - line.y1);

    return dx > dy ? 4 : 2;
}

export function getSelectionProf(profiles, sel, defualt){
    if(profiles.length === 0) return null;

    if(!sel && !defualt) return profiles[0];

    let prof;
    if(sel){
        prof = profiles.find(obj => obj.id === sel.selRama);
        if(prof) return prof;
    }
    if(defualt){
        prof = profiles.find(obj => obj.id === defualt);
        if(prof) return prof;
    }

    return profiles[0];
}

export function getInsetPolygon(points, profiles, defualt, type = 1) {
    const frames = [];
    const n = points.length;

    for (let i = 0; i < n; i++) {
        // Предыдущая точка (с учетом замыкания многоугольника)
        let p0 = points[(i - 1 + n) % n];
        // Текущая точка
        const p1 = points[i];
        // Следующая точка (с учетом замыкания многоугольника)
        let p2 = points[(i + 1) % n];

        const prof1 = getSelectionProf(profiles, p0?.frame, defualt)
        const prof2 = getSelectionProf(profiles, p1?.frame, defualt)
        if(!prof1 || !prof2){
            return 'Не смогли найти раму';
        }
        const distance1 = prof1?.wr||20;
        const distance2 = prof2?.wr||20;
        if(p1.or){
            p2 = findPointOnArc(p1, p2, 1, distance2)
        }
        if(p0.or){
            p0 = findPointOnArc(p0, p1, 2, distance1)
        }
        const ligamentType = getLigamentTyp(prof1, prof2, {x1:p0.ox, y1:p0.oy, x2:p1.ox, y2:p1.oy} );
        if(!ligamentType){
            return 'невозможно связывать профиль '+prof1.articul+' с профилем '+prof2.articul;
        }
        const {centerX, centerY} = calculateCentroid(points)
        const shiftLine1 = shiftLineClosestToPoint({x1:p0.ox, y1:p0.oy, x2:p1.ox, y2:p1.oy}, {x:centerX, y:centerY}, distance1)
        const shiftLine2 = shiftLineClosestToPoint({x1:p1.ox, y1:p1.oy, x2:p2.ox, y2:p2.oy}, {x:centerX, y:centerY}, distance2)
        if(arePointsCollinear(p0.ox, p0.oy, p1.ox, p1.oy, p2.ox, p2.oy)){
            if(type === 2){
                updateOrAddFrame(frames, i, {
                    x:shiftLine2.x1,
                    y:shiftLine2.y1,
                    r:p1?.or
                })
                continue
            }
            updateOrAddFrame(frames, i, {
                p1:{x:p1.ox, y:p1.oy, r:p1?.or, frame:p1?.frame, type:p1?.type, index:p1?.index},
                p3:{x:shiftLine2.x1, y:shiftLine2.y1, r:p1?.or}
            })
            updateOrAddFrame(frames, (i - 1 + n) % n, {
                p2:{x:p1.ox, y:p1.oy},
                p4:{x:shiftLine1.x2, y:shiftLine1.y2},
            })
            continue;
        }
        const inter1 = calculateIntersection(
            {x:shiftLine1.x1, y:shiftLine1.y1},
            {x:shiftLine1.x2, y:shiftLine1.y2},
            {x:shiftLine2.x1, y:shiftLine2.y1},
            {x:shiftLine2.x2, y:shiftLine2.y2})
        if(!inter1){
            return 'невозможно связывать профили'
        }
        if(type === 2){
            updateOrAddFrame(frames, i, {
                x:inter1.x,
                y:inter1.y,
                r:p1?.or
            })
            continue
        }
        if(ligamentType === 1){
            updateOrAddFrame(frames, i, {
                p1:{x:p1.ox, y:p1.oy, r:p1?.or, frame:p1?.frame, type:p1?.type, index:p1?.index},
                p3:{x:inter1.x, y:inter1.y, r:p1?.or},
            })
            updateOrAddFrame(frames, (i - 1 + n) % n, {
                p2:{x:p1.ox, y:p1.oy},
                p4:{x:inter1.x, y:inter1.y},
            })
        }
        if(ligamentType === 2){
            const inter2 = calculateIntersection(
                {x:shiftLine1.x1, y:shiftLine1.y1},
                {x:shiftLine1.x2, y:shiftLine1.y2},
                {x:p1.ox, y:p1.oy},
                {x:p2.ox, y:p2.oy})
            if(!inter2){
                return 'невозможно связывать профили'
            }
            updateOrAddFrame(frames, i, {
                p1:{x:inter2.x, y:inter2.y, r:p1?.or, frame:p1?.frame, type:p1?.type, index:p1?.index},
                p3:{x:inter1.x, y:inter1.y, r:p1?.or},
            })
            updateOrAddFrame(frames, (i - 1 + n) % n, {
                p2:{x:p1.ox, y:p1.oy},
                p4:{x:inter2.x, y:inter2.y},
            })
        }
        if(ligamentType === 3){
            const inter2 = calculateIntersection(
                {x:shiftLine2.x1, y:shiftLine2.y1},
                {x:shiftLine2.x2, y:shiftLine2.y2},
                {x:p0.ox, y:p0.oy},
                {x:p1.ox, y:p1.oy})
            if(!inter2){
                return 'невозможно связывать профили'
            }
            updateOrAddFrame(frames, i, {
                p1:{x:p1.ox, y:p1.oy, r:p1?.or, frame:p1?.frame, type:p1?.type, index:p1?.index},
                p3:{x:inter2.x, y:inter2.y, r:p1?.or},
            })
            updateOrAddFrame(frames, (i - 1 + n) % n, {
                p2:{x:inter2.x, y:inter2.y},
                p4:{x:inter1.x, y:inter1.y},
            })
        }
    }
    return frames;
}

function arePointsCollinear(x1, y1, x2, y2, x3, y3) {
    // Вычисляем площадь треугольника
    const area = Math.abs(
        x1 * (y2 - y3) +
        x2 * (y3 - y1) +
        x3 * (y1 - y2)
    );

    // Если площадь равна 0, точки коллинеарны
    return area === 0;
}

function updateOrAddFrame(frames, index, points) {
    if (frames[index]) {
        // Обновляем существующий объект
        Object.assign(frames[index], points);
    } else {
        // Создаем новый объект по указанному индексу
        frames[index] = points;
    }
}

const calculateIntersection = (p1, p2, p3, p4) => {
    const denominator = (p4.y - p3.y) * (p2.x - p1.x) - (p4.x - p3.x) * (p2.y - p1.y);
    if (denominator === 0) {
        return null; // Линии параллельны
    }

    const ua = ((p4.x - p3.x) * (p1.y - p3.y) - (p4.y - p3.y) * (p1.x - p3.x)) / denominator;
    return {
        x: p1.x + ua * (p2.x - p1.x),
        y: p1.y + ua * (p2.y - p1.y)
    };
};