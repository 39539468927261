import {isPointOnLine, isPointOnArc, getDistance} from './matf';

function isLineOnPolygon(p1, p2, polygon) {
    for (let i = 0; i < polygon.length; i++) {
        const ni = (i + 1) % polygon.length;
        const p1Poly = polygon[i];
        const p2Poly = polygon[ni];
        if ( (((p1.x === p1Poly.x && p1.y === p1Poly.y) && (p2.x === p2Poly.x && p2.y === p2Poly.y)) ||
            ((p1.x === p2Poly.x && p1.y === p2Poly.y) && (p2.x === p1Poly.x && p2.y === p1Poly.y))) &&  p1?.r === p1Poly?.r) {
            return true;
        }
    }
    return false;
}

function sortPointsRelativeTo(basePoint, points) {
    return points.slice().sort((a, b) => {
        // Вычисляем углы относительно опорной точки
        const angleA = Math.atan2(a.y - basePoint.y, a.x - basePoint.x);
        const angleB = Math.atan2(b.y - basePoint.y, b.x - basePoint.x);

        // Если углы разные, сортируем по углу
        if (angleA !== angleB) {
            return angleA - angleB;
        }

        // Если углы одинаковы, сортируем по расстоянию от опорной точки
        const distanceA = Math.hypot(a.x - basePoint.x, a.y - basePoint.y);
        const distanceB = Math.hypot(b.x - basePoint.x, b.y - basePoint.y);

        return distanceA - distanceB;
    });
}

export function calculateCentroid(points) {
    if (points.length < 3) {
        return  { centerX:points[0].x, centerY:points[0].y };
    }

    let area = 0; // Полная площадь многоугольника
    let centerX = 0;
    let centerY = 0;

    for (let i = 0; i < points.length; i++) {
        const { x: x1, y: y1 } = points[i];
        const { x: x2, y: y2 } = points[(i + 1) % points.length]; // Следующая точка (циклически)

        const crossProduct = x1 * y2 - x2 * y1;

        area += crossProduct;
        centerX += (x1 + x2) * crossProduct;
        centerY += (y1 + y2) * crossProduct;
    }

    area = area / 2; // Полная площадь
    if (area === 0) {
        throw new Error("The points do not form a valid polygon");
    }

    // Центроид
    centerX = centerX / (6 * area);
    centerY = centerY / (6 * area);

    return { centerX, centerY };
}


function arePolygonsEqual(poly1, poly2) {
    if (poly1.length !== poly2.length) return false;

    // Нормализация — поиск точки с минимальными координатами
    const normalizePolygon = (polygon) => {
        const minIndex = polygon.reduce((minIdx, point, idx, arr) =>
                point.x < arr[minIdx].x || (point.x === arr[minIdx].x && point.y < arr[minIdx].y) ? idx : minIdx
            , 0);
        return polygon.slice(minIndex).concat(polygon.slice(0, minIndex));
    };

    const isSamePolygon = (p1, p2) => p1.every((point, idx) =>
        point.x === p2[idx].x && point.y === p2[idx].y
    );

    const normalized1 = normalizePolygon(poly1);
    const normalized2 = normalizePolygon(poly2);
    const reversed2 = [...normalized2].reverse();

    return isSamePolygon(normalized1, normalized2) || isSamePolygon(normalized1, reversed2);
}

function splitPolygon(polygon, lines) {
    let currentPolygon = [...polygon];
    let li = -1
    let ii = -1
    for (let line of lines) {
        ii++
        if(isLineOnPolygon(line[0], line[1], polygon) || (line[2] && isLineOnPolygon(line[2], line[3], polygon))) continue;
        let intersections = [];
        const normalizeLine = [...line];//!line[2] || getDistance(polygon[0].x, polygon[0].y, line[0].x, line[0].y) < getDistance(polygon[0].x, polygon[0].y, line[2].x, line[2].y) ? [...line] : [line[2], line[3], line[0], line[1]];
        if(line.length === 4){
            intersections = getPolygons(polygon, [[line[0],line[1]],[line[2],line[3]]]);
            if (intersections.length < 3) {
                continue;
            }
            intersections = intersections.filter(polygon => {
                const hasPointA = polygon.some(point => point.x === line[0].x && point.y === line[0].y);
                const hasPointB = polygon.some(point => point.x === line[2].x && point.y === line[2].y);
                return !(hasPointA && hasPointB); // Убираем, если есть обе точки
            });
            currentPolygon = [intersections[0], intersections[1]];
            li = ii;
            break;
        }
        polygon.forEach((point, index) => {
            const ni = (index + 1) % polygon.length;
            let p1 = point;
            let p2 = polygon[ni];
            if(p1.r){
                if(isPointOnArc({x: p1.x, y: p1.y, r: Math.abs(p1.r), or: p1.r}, {x: p2.x, y: p2.y}, normalizeLine[0].x, normalizeLine[0].y, 1)){
                    intersections.push({ point: normalizeLine[0], index});
                }
                if(isPointOnArc({x: p1.x, y: p1.y, r: Math.abs(p1.r), or: p1.r}, {x: p2.x, y: p2.y}, normalizeLine[1].x, normalizeLine[1].y, 1)){
                    intersections.push({ point: normalizeLine[1], index});
                }
            }else{
                if (isPointOnLine(p1.x, p1.y, p2.x, p2.y, normalizeLine[0].x, normalizeLine[0].y, 1)) {
                    intersections.push({ point: normalizeLine[0], index});
                }
                if (isPointOnLine(p1.x, p1.y, p2.x, p2.y, normalizeLine[1].x, normalizeLine[1].y, 1)) {
                    intersections.push({ point: normalizeLine[1], index});
                }
            }
        })
        // Если пересечений нет, переходим к следующей линии
        if (intersections.length < 2) {
            continue;
        }

        // Получим индексы пересечений и разделим полигон
        const i1 = intersections[0].index;
        const i2 = intersections[1].index;
        const pt1 = intersections[0].point;
        const pt2 = intersections[1].point;
        const pt3 = {...pt1};
        const pt4 = {...pt2};
        if(pt1?.r){
            pt4.r = pt1.r < 1 ? Math.abs(pt1.r) : -Math.abs(pt1.r);
            pt3.r = pt2.r = null;
        }
        if(pt2?.r){
            pt1.r  = pt2.r < 1 ? Math.abs(pt2.r) : -Math.abs(pt2.r)
            pt4.r = pt2.r;
            pt3.r = pt2.r = null;
        }
        if(polygon[i1]?.r){
            pt3.r = polygon[i1].r;
        }
        if(polygon[i2]?.r){
            pt2.r = polygon[i2].r;
        }
        pt3.index = polygon[i1]?.index
        pt2.index = polygon[i2]?.index
        pt3.type = polygon[i1]?.type
        pt2.type = polygon[i2]?.type

        let part1 = polygon.slice(0, i1 + 1).concat([pt1, pt2], polygon.slice(i2 + 1));
        let part2 = [pt3].concat(polygon.slice(i1 + 1, i2 + 1), [pt4]);
        if(!part1.length || !part2.length) continue
        // Разделим полигон на две части
        currentPolygon = [part1, part2];
        li = ii;
        break;
    }

    return {pols:currentPolygon, line: li};
}

function clearExtraPoints(points, pt1, pt2) {
    return points.filter(point => {
        // Проверяем, если x точки находится между pt1.x и pt2.x
        const isXInRange = pt1.x < point.x && point.x < pt2.x;

        // Проверяем, если y точки находится между pt1.y и pt2.y
        const isYInRange = pt1.y < point.y && point.y < pt2.y;

        // Возвращаем true, если точка не находится в пределах (между pt1 и pt2)
        return !(isXInRange || isYInRange);
    });
}

export function getPolygons(polygon, lines) {
    if (!polygon.length || !lines.length) return [polygon];

    let renderPolygons = [];
    let polygons = [];
    let linesCopy = [...lines];

    function processPolygonSplit(currentPolygon) {
        const { pols, line } = splitPolygon(currentPolygon, linesCopy);
        if (!pols || !Array.isArray(pols) || typeof line !== 'number') {
            throw new Error("Invalid result from splitPolygon");
        }

        if (pols.length === 2) {
            if (line >= 0) linesCopy.splice(line, 1);
            renderPolygons.push(pols[0], pols[1]);
        } else {
            polygons.push(pols);
        }
    }
    if(polygon.some(item => Array.isArray(item))){
        renderPolygons = [...polygon]
    }else{
        processPolygonSplit(polygon);
    }
    // Первый вызов обработки
    // Цикл обработки очереди
    while (renderPolygons.length) {
        if (linesCopy.length === 0) {
            polygons.push(renderPolygons.shift());
        } else {
            processPolygonSplit(renderPolygons.shift());
        }
    }

    return polygons;
}
