export function findMidpoint(A, B) {
    const midpoint = {
        x: (A.x + B.x) / 2,
        y: (A.y + B.y) / 2
    };
    return midpoint;
}

export function getDistance(x1, y1, x2, y2) {
    const deltaX = x2 - x1;
    const deltaY = y2 - y1;
    return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
}

export function calculateRadiusByH(x1, y1, x2, y2, height) {
    const h = Math.abs(height)
    // Вычисление длины хорды
    const d = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    // Вычисление радиуса
    const R = (Math.pow(d, 2) / (8 * h)) + (h / 2);

    return height < 0 ? R * -1 : R;
}

export function calculateHeightByR(x1, y1, x2, y2, radius) {
    const R = Math.abs(radius)
    
    const d = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    // Высота дуги для меньшей дуги
    const h = R - Math.sqrt(Math.pow(R, 2) - Math.pow(d / 2, 2));

    return radius < 0 ? h * -1 : h;
}

export function findPointsOnArc(A, B, n) {
    const radius = Math.abs(A.or)
    const C = calculateArcCenter({x:A.ox, y:A.oy, or: A.or}, {x:B.ox, y:B.oy, or: B.or}, radius);
    const angleA = Math.atan2(A.oy - C.y, A.ox - C.x);
    const angleB = Math.atan2(B.oy - C.y, B.ox - C.x);
    
    // Определяем порядок дуги
    let startAngle = angleA, endAngle = angleB;

    if (endAngle < startAngle) {
        endAngle += 2 * Math.PI;
    }
    
    // Проверка, какой путь короче: прямой или противоположный
    if (endAngle - startAngle > Math.PI  || (endAngle - startAngle == Math.PI && A.or < 0)) {
        let temp = startAngle;
        startAngle = endAngle;
        endAngle = temp + 2 * Math.PI;
    }
    const points = [];
    // Угол между точками
    const angleDiff = endAngle - startAngle;
    const angleStep = angleDiff / (n - 1);
    for (let i = 0; i < n; i++) {
        // Текущий угол для точки
        const currentAngle = A.or < 0 ? endAngle - i * angleStep : startAngle + i * angleStep;
        
        let x = C.x + radius * Math.cos(currentAngle);
        let y = C.y + radius * Math.sin(currentAngle);
        
        // Округляем до 5 знаков после запятой
        x = parseFloat(x.toFixed(1));
        y = parseFloat(y.toFixed(1));
        
        points.push({ x, y });
    }
    
    return points;
}

export function findArcMidpoint(A, B) {
    // Средняя точка отрезка AB
    const R = A.r
    const C = calculateArcCenter(A, B, R);

    const thetaA = Math.atan2(A.y - C.y, A.x - C.x);
    const thetaB = Math.atan2(B.y - C.y, B.x - C.x);

    // Если нужно пройти в положительном направлении
    let thetaM;
    if (thetaB < thetaA) {
        thetaM = (thetaA + thetaB + 2 * Math.PI) / 2;
    } else {
        thetaM = (thetaA + thetaB) / 2;
    }

    // Вычисляем координаты центральной точки дуги
    const midX = C.x + R * Math.cos(thetaM);
    const midY = C.y + R * Math.sin(thetaM);

    return { x: midX, y: midY };
}

export function getArcCoords(c1, c2, type = false) {
    var radius = c1.r;

    const center = calculateArcCenter(c1, c2, radius)
    const angleA = Math.atan2(c1.y - center.y, c1.x - center.x);
    const angleB = Math.atan2(c2.y - center.y, c2.x - center.x);

    // Определяем порядок дуги
    let startAngle = angleA, endAngle = angleB;

    if (endAngle < startAngle) {
        endAngle += 2 * Math.PI;
    }
    
    // Проверка, какой путь короче: прямой или противоположный
    if (endAngle - startAngle > Math.PI  || (endAngle - startAngle == Math.PI && c1.or < 0)) {
        let temp = startAngle;
        startAngle = endAngle;
        endAngle = temp + 2 * Math.PI;
    }

    return {
        left: center.x - radius,
        top: center.y - radius,
        radius: radius,
        startAngle: startAngle,
        endAngle: endAngle
    };
}

export function isPointOnLine(x1, y1, x2, y2, x, y, thickness=6) {
    var dx = x2 - x1;
    var dy = y2 - y1;
    var len = Math.sqrt(dx * dx + dy * dy);
    var distance = Math.abs(dy * x - dx * y + x2 * y1 - y2 * x1) / len;

    if (distance > thickness) {
        return false;
    }

    // Проверка, что точка C находится между A и B (если нас интересует отрезок, а не бесконечная прямая)
    const minX = Math.min(x1, x2);
    const maxX = Math.max(x1, x2);
    const minY = Math.min(y1, y2);
    const maxY = Math.max(y1, y2);

    if (x < minX - thickness || x > maxX + thickness || y < minY - thickness || y > maxY + thickness) {
        return false; // Точка не лежит на отрезке с учетом толщины
    }

    return true; // Точка принадлежит отрезку с учетом толщины
}

export function isPointOnArc(A, B, x, y, thickness = 6) {
    const center = calculateArcCenter(A, B, A.r);
    const angleA = Math.atan2(A.y - center.y, A.x - center.x);
    const angleB = Math.atan2(B.y - center.y, B.x - center.x);

    // Определяем порядок дуги
    let startAngle = angleA, endAngle = angleB;

    if (endAngle < startAngle) {
        endAngle += 2 * Math.PI;
    }
    
    // Проверка, какой путь короче: прямой или противоположный
    if (endAngle - startAngle > Math.PI  || (endAngle - startAngle == Math.PI && A.or < 0)) {
        let temp = startAngle;
        startAngle = endAngle;
        endAngle = temp + 2 * Math.PI;
    }
    function isPointOnCircle(cx, cy, r, x, y) {
        const distance = Math.sqrt((x - cx) ** 2 + (y - cy) ** 2);
        return distance <= r + thickness / 2 && distance >= r - thickness / 2;
    }

    if(!isPointOnCircle(center.x, center.y, A.r, x, y)){
        return false;
    }

    const angle = Math.atan2(y - center.y, x - center.x);
    const normalizeAngle = (angle) => (angle + 2 * Math.PI) % (2 * Math.PI);
    const normalizedAngle1 = normalizeAngle(startAngle);
    const normalizedAngle2 = normalizeAngle(endAngle);
    const normalizedAngle = normalizeAngle(angle);

    if (normalizedAngle1 < normalizedAngle2) {
        return normalizedAngle >= normalizedAngle1 && normalizedAngle <= normalizedAngle2;
    } else {
        return normalizedAngle >= normalizedAngle1 || normalizedAngle <= normalizedAngle2;
    }
  }

  export function isPointAddProfOnArc(A, B, x, y, R, thickness = 6) {
    const center = calculateArcCenter(A, B, A.r);
    const angleA = Math.atan2(A.y - center.y, A.x - center.x);
    const angleB = Math.atan2(B.y - center.y, B.x - center.x);

    // Определяем порядок дуги
    let startAngle = angleA, endAngle = angleB;

    if (endAngle < startAngle) {
        endAngle += 2 * Math.PI;
    }
    
    // Проверка, какой путь короче: прямой или противоположный
    if (endAngle - startAngle > Math.PI  || (endAngle - startAngle == Math.PI && A.or < 0)) {
        let temp = startAngle;
        startAngle = endAngle;
        endAngle = temp + 2 * Math.PI;
    }
    function isPointOnCircle(cx, cy, r, x, y) {
        const distance = Math.sqrt((x - cx) ** 2 + (y - cy) ** 2);
        return distance <= r + thickness / 2 && distance >= r - thickness / 2;
    }

    if(!isPointOnCircle(center.x, center.y, R, x, y)){
        return false;
    }

    const angle = Math.atan2(y - center.y, x - center.x);
    const normalizeAngle = (angle) => (angle + 2 * Math.PI) % (2 * Math.PI);
    const normalizedAngle1 = normalizeAngle(startAngle);
    const normalizedAngle2 = normalizeAngle(endAngle);
    const normalizedAngle = normalizeAngle(angle);

    if (normalizedAngle1 < normalizedAngle2) {
        return normalizedAngle >= normalizedAngle1 && normalizedAngle <= normalizedAngle2;
    } else {
        return normalizedAngle >= normalizedAngle1 || normalizedAngle <= normalizedAngle2;
    }
  }

  export function calculateArcCenter(A, B, R) {
    const midPoint = { x: (A.x + B.x) / 2, y: (A.y + B.y) / 2 };
    const d = Math.sqrt((B.x - A.x) ** 2 + (B.y - A.y) ** 2);
    const h = Math.sqrt(R ** 2 - (d / 2) ** 2);
    const normalX = (B.y - A.y) / d;
    const normalY = (A.x - B.x) / d;
    const center1 = { x: midPoint.x + h * normalX, y: midPoint.y + h * normalY };
    const center2 = { x: midPoint.x - h * normalX, y: midPoint.y - h * normalY };
    return A.or && A.or < 0 ? center1 : center2; // Возможно, потребуется выбрать центр в зависимости от направления дуги
  }

  export function findArcMinMax(A, B, type=false){
    const radius = A.r;
    const center = calculateArcCenter(A, B, radius);
    const angleA = Math.atan2(A.y - center.y, A.x - center.x);
    const angleB = Math.atan2(B.y - center.y, B.x - center.x);
    // Определяем порядок дуги
    let startAngle = angleA, endAngle = angleB;

    if (endAngle < startAngle) {
        endAngle += 2 * Math.PI;
    }
    
    // Проверка, какой путь короче: прямой или противоположный
    if (endAngle - startAngle > Math.PI || (endAngle - startAngle == Math.PI && A.or < 0)) {
        let temp = startAngle;
        startAngle = endAngle;
        endAngle = temp + 2 * Math.PI;
    }

    // Число точек для интерполяции
    const steps = 50;
    let minX = Infinity, minY = Infinity;
    let maxX = -Infinity, maxY = -Infinity;

    // Интерполируем точки на дуге
    for (let i = 0; i <= steps; i++) {
        const t = i / steps;
        const angle = startAngle + t * (endAngle - startAngle);
        
        const x = center.x + radius * Math.cos(angle);
        const y = center.y + radius * Math.sin(angle);
        
        // Обновляем минимальные значения
        if (x < minX) minX = x;
        if (y < minY) minY = y;
        
        // Обновляем максимальные значения
        if (x > maxX) maxX = x;
        if (y > maxY) maxY = y;
    }
    return { minX, minY, maxX, maxY };
  }

export function getScalePoinst(points, canvasWidth, canvasHeight, offset, separators) {
    const mx = minMaxCords(points, 'o');
    const width = mx.maxX - mx.minX;
    const height = mx.maxY - mx.minY;
    const scale = Math.min((canvasWidth - 2 * offset) / width, (canvasHeight - 2 * offset) / height);
    var left = 0, top = 0;
    if((canvasWidth - 2 * offset) / width < (canvasHeight - 2 * offset) / height){
        top = (canvasHeight - (height*scale) - 2 * offset) / 2;
    }else{
        left = (canvasWidth - (width*scale) - 2 * offset) / 2;
    }
    top = top > 0 ? Math.round(top) : 0;
    left = left > 0 ? Math.round(left) : 0;
    // Определяем масштаб
    const rPoints = points.map(point => ({
        ...point,
        x: (point.ox - mx.minX) * scale + offset + left,
        y: (point.oy - mx.minY) * scale + offset + top,
        r: point.or ? Math.ceil(Math.abs(point.or) * scale * 1000) / 1000 : null
    }));
    const rSeparators = separators.map(point => ({
        ...point,
        x1: (point.ox1 - mx.minX) * scale + offset + left,
        y1: (point.oy1 - mx.minY) * scale + offset + top,
        x2: (point.ox2 - mx.minX) * scale + offset + left,
        y2: (point.oy2 - mx.minY) * scale + offset + top,
        r: point.or ? Math.ceil( Math.abs(point.or) * scale) : null
    }));
    return { scale: scale, points: rPoints, mx, separators:rSeparators, left, top };
}

export function isPointOutsideCanvas(point, padding = 50) {
    const canvasWidth = this.canvas.getWidth();
    const canvasHeight = this.canvas.getHeight();

    // Проверяем, выходит ли точка за пределы холста с учетом padding
    return point.x < padding || point.x > canvasWidth - padding || point.y < padding || point.y > canvasHeight - padding;
}

export function isCornersElement(point, circle){
    var centerX = circle.left + circle.radius;
    var centerY = circle.top + circle.radius;

    // Вычисляем расстояние от центра круга до точки
    var distance = Math.sqrt((point.x - centerX) ** 2 + (point.y - centerY) ** 2);

    // Проверяем, находится ли точка внутри круга
    return distance <= circle.radius;
}

export function minMaxCords(points, ct=''){
    let minX = points[0][ct+'x'];
    let minY = points[0][ct+'y'];
    let maxX = points[0][ct+'x'];
    let maxY = points[0][ct+'y'];

    points.forEach((point, index) => {
        if (point.or){
            var nextIndex = (index + 1) % points.length;
            var coords = findArcMinMax({x:points[index][ct+'x'], y: points[index][ct+'y'], r: Math.abs(points[index][ct+'r']), or: point.or }, {x:points[nextIndex][ct+'x'], y: points[nextIndex][ct+'y']});
            if (coords.minX < minX) minX = coords.minX;
            if (coords.minY < minY) minY = coords.minY;
            if (coords.maxX > maxX) maxX = coords.maxX;
            if (coords.maxY > maxY) maxY = coords.maxY;
        }else{
            if (point[ct+'x'] < minX) minX = point[ct+'x'];
            if (point[ct+'y'] < minY) minY = point[ct+'y'];
            if (point[ct+'x'] > maxX) maxX = point[ct+'x'];
            if (point[ct+'y'] > maxY) maxY = point[ct+'y'];
        }
    });
    minX = parseFloat(minX.toFixed(1))
    minY = parseFloat(minY.toFixed(1))
    maxX = parseFloat(maxX.toFixed(1))
    maxY = parseFloat(maxY.toFixed(1))
    return {minX, minY, maxX, maxY}
}
 export function checkRadius(points){
    const rPoints = points.map((point, index) => {
        if (point.or) {
            var radius = point.or;
            var minus = false;
            if(point.or < 0){
                radius = Math.abs(radius)
                minus = true
            }
            var nextIndex = (index + 1) % points.length;
            const dx = points[nextIndex].ox - point.ox;
            const dy = points[nextIndex].oy - point.oy;
            const distance = Math.sqrt(dx * dx + dy * dy);
            const h = radius - Math.sqrt(Math.pow(radius, 2) - Math.pow(distance / 2, 2));
            const minRadius = distance / 2;
            if (radius < minRadius) {
                const or = minus ? minRadius * -1 : minRadius;
                return { ...point, or: Math.ceil(or) };
            }else if(h <= 5){
                const or = minus ? minRadius * -1 : minRadius;
                return { ...point, or: Math.ceil(point.or/2) };
            }
        }
        return point;
    });

    return rPoints;
 }

 export function findIntersection(line1, line2) {
    // Координаты первой линии (две произвольные точки)
    const { x1, y1, x2, y2 } = line1;

    // Координаты второй линии (начало и конец)
    const { x3, y3, x4, y4 } = line2;

    // if(isPointOnLine(x3, y3, x4, y4, x1, y1, 2) || isPointOnLine(x3, y3, x4, y4, x2, y2, 2)) return null;


    const A1 = y2 - y1;
    const B1 = x1 - x2;
    const C1 = A1 * x1 + B1 * y1;

    const A2 = y4 - y3;
    const B2 = x3 - x4;
    const C2 = A2 * x3 + B2 * y3;

    // Вычисляем знаменатель для проверки на параллельность
    const denominator = A1 * B2 - A2 * B1;

    if (denominator === 0) {
        return null; // Линии параллельны или совпадают
    }

    // Вычисляем координаты точки пересечения
    const intersectionX = (B2 * C1 - B1 * C2) / denominator;
    const intersectionY = (A1 * C2 - A2 * C1) / denominator;
    // Если точка пересечения находится на обоих отрезках, вернуть её
    if (isPointOnLine(x3, y3, x4, y4, intersectionX, intersectionY, 2)) {
        return { x: parseFloat(intersectionX.toFixed(1)), y: parseFloat(intersectionY.toFixed(1)) };
    }

    return null; // Линии не пересекаются в пределах отрезков
}

export function findIntersectionArc(line1, line2) {

    // Координаты первой линии (две произвольные точки)
    const { x1, y1, x2, y2 } = line1;

    // Координаты дуги
    const { x3, y3, x4, y4, R } = line2;

    // Проверяем, если одна из точек линии уже лежит на дуге
    // if (isPointOnArc({x: x3, y: y3, r: Math.abs(R), or: R}, {x: x4, y: y4}, x1, y1, 0) ||
    //     isPointOnArc({x: x3, y: y3, r: Math.abs(R), or: R}, {x: x4, y: y4}, x2, y2, 0)) {
    //     return null;
    // }

    // Рассчитываем центр дуги
    const center = calculateArcCenter({x: x3, y: y3, r: Math.abs(R), or: R}, {x: x4, y: y4}, Math.abs(R));
    
    // Вектор линии
    const dx = x2 - x1;
    const dy = y2 - y1;

    // Параметрическое уравнение прямой: (x, y) = (x1, y1) + t * (dx, dy)
    const A = dx * dx + dy * dy;
    const B = 2 * (dx * (x1 - center.x) + dy * (y1 - center.y));
    const C = (x1 - center.x) * (x1 - center.x) + (y1 - center.y) * (y1 - center.y) - R * R;

    // Дискриминант квадратного уравнения
    const discriminant = B * B - 4 * A * C;

    if (discriminant < 0) {
        return null; // Нет пересечения
    }

    // Найдем значения t для возможных точек пересечения
    const sqrtDiscriminant = Math.sqrt(discriminant);
    const t1 = (-B + sqrtDiscriminant) / (2 * A);
    const t2 = (-B - sqrtDiscriminant) / (2 * A);

    const intersection1 = { x: parseFloat((x1 + t1 * dx).toFixed(1)), y: parseFloat((y1 + t1 * dy).toFixed(1)) };
    const intersection2 = { x: parseFloat((x1 + t2 * dx).toFixed(1)), y: parseFloat((y1 + t2 * dy).toFixed(1)) };

    // Проверяем, находится ли каждая точка пересечения на дуге
    const validIntersections = [];
    if (isPointOnArc({x: x3, y: y3, r: Math.abs(R), or: R}, {x: x4, y: y4}, intersection1.x, intersection1.y, 2)) {
        validIntersections.push(intersection1);
    }
    if (isPointOnArc({x: x3, y: y3, r: Math.abs(R), or: R}, {x: x4, y: y4}, intersection2.x, intersection2.y, 2)) {
        validIntersections.push(intersection2);
    }

    // Возвращаем точку пересечения, если она существует
    return validIntersections.length ? validIntersections : null;
}

export function isPointInsidePolygon(points, point) {
    const pa = isPointInsideArc(points, point);
    if(pa === 2) return false;

    if(pa === 1){
        return true
    }else{
        return isPointInPolygon(points, point)
    }
}
export function isPointInsidePolygonLines(points, point) {
    var res = false;
    for (var i = 0; i < points.length; i++) {
        var nextIndex = (i + 1) % points.length;
        var is = points[i].r 
        ? isPointOnArc(points[i], points[nextIndex], point.x, point.y)
        : isPointOnLine(points[i].x, points[i].y, points[nextIndex].x, points[nextIndex].y, point.x, point.y);
        if (is) {
            res = true;
            break;
        }
    }
    return res;
}

export function isPointInPolygon(polygon, point) {
    let x = point.x, y = point.y;
    let inside = false;
    
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        let xi = polygon[i].x, yi = polygon[i].y;
        let xj = polygon[j].x, yj = polygon[j].y;
        
        let intersect = ((yi > y) != (yj > y)) &&
                        (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
                        
        if (intersect) inside = !inside;
    }
    
    return inside;
}

export function isPointInsideArc(points, point){
    let res = 0
    for (var i = 0; i < points.length; i++) {
        var ni = (i + 1) % points.length;
        if (points[i].r && isPointInSector(points[i], points[ni], points[i].r, point)) {
          res = points[i].or < 0 ? 2 : 1;
          break;
        }
    }
    return res;
}

export function isPointInArcBand(A, B, outerRadius, thickness, point) {
    const innerRadius = A.or < 0 ? outerRadius : outerRadius - thickness;
    if(A.or < 0){
        outerRadius = outerRadius+thickness
    }
    const center = calculateArcCenter(A, B, A.r);
    // Найдем углы начала и конца дуги
    let startAngle = Math.atan2(A.y - center.y, A.x - center.x);
    let endAngle = Math.atan2(B.y - center.y, B.x - center.x);

    // Если угол конца меньше угла начала, увеличим его на 2π
    if (endAngle < startAngle) {
        endAngle += 2 * Math.PI;
    }
     // Проверка, какой путь короче: прямой или противоположный
     if (endAngle - startAngle > Math.PI || (endAngle - startAngle == Math.PI && A.or < 0)) {
        let temp = startAngle;
        startAngle = endAngle;
        endAngle = temp + 2 * Math.PI;
    }

    // Вычислим расстояние от точки до центра окружности
    const dx = point.x - center.x;
    const dy = point.y - center.y;
    const distance = Math.sqrt(dx * dx + dy * dy);

    // Проверим, находится ли расстояние точки между радиусами
    if (distance < innerRadius || distance > outerRadius) {
        return false;
    }

    // Вычислим угол точки относительно центра окружности
    const angle = Math.atan2(dy, dx);

    // Приведем углы к диапазону [0, 2π]
    const normalizedAngle = (angle + 2 * Math.PI) % (2 * Math.PI);
    const normalizedStartAngle = (startAngle + 2 * Math.PI) % (2 * Math.PI);
    const normalizedEndAngle = (endAngle + 2 * Math.PI) % (2 * Math.PI);

    // Проверим, пересекает ли дуга 0
    if (normalizedStartAngle <= normalizedEndAngle) {
        return normalizedAngle >= normalizedStartAngle && normalizedAngle <= normalizedEndAngle;
    } else {
        return normalizedAngle >= normalizedStartAngle || normalizedAngle <= normalizedEndAngle;
    }
}

export function isPointInSector(A, B, radius, point) {
    const center = calculateArcCenter(A, B, radius);
    let startAngle = Math.atan2(A.y - center.y, A.x - center.x);
    let endAngle = Math.atan2(B.y - center.y, B.x - center.x);
    if (endAngle < startAngle) {
        endAngle += 2 * Math.PI;
    }
    // Проверка, какой путь короче: прямой или противоположный
    if (endAngle - startAngle > Math.PI || (endAngle - startAngle == Math.PI && A.or < 0)) {
        let temp = startAngle;
        startAngle = endAngle;
        endAngle = temp + 2 * Math.PI;
    }

    const dx = point.x - center.x;
    const dy = point.y - center.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    
    // Проверьте, находится ли точка внутри радиуса сектора
    if (distance > radius) {
        return false;
    }
    
    // Вычислите угол точки относительно центра сектора
    const angle = Math.atan2(dy, dx);
    
    // Приведите углы к диапазону от 0 до 2π
    const normalizedAngle = (angle + 2 * Math.PI) % (2 * Math.PI);
    const normalizedTheta1 = (startAngle + 2 * Math.PI) % (2 * Math.PI);
    const normalizedTheta2 = (endAngle + 2 * Math.PI) % (2 * Math.PI);
    
    // Проверьте, находится ли угол точки в пределах углов сектора
    if (normalizedTheta1 <= normalizedTheta2) {
        return normalizedAngle >= normalizedTheta1 && normalizedAngle <= normalizedTheta2;
    } else {
        return normalizedAngle >= normalizedTheta1 || normalizedAngle <= normalizedTheta2;
    }
}

export function lineLinesIntersections(line, lines){
    var pointsSep = []
    const lcX = (line.ox1 + line.ox2) / 2;
    const lcY = (line.oy1 + line.oy2) / 2;
    lines.forEach((ll, index) => {
        let it = !ll.r ?
            findIntersection({ x1: line.ox1, y1: line.oy1, x2: line.ox2, y2: line.oy2 }, { x3: ll.x1, y3: ll.y1, x4: ll.x2, y4: ll.y2 })
            :
            findIntersectionArc({ x1: line.ox1, y1: line.oy1, x2: line.ox2, y2: line.oy2 }, { x3: ll.x1, y3: ll.y1, x4: ll.x2, y4: ll.y2, R: ll.r});
        if(it){
            if(Array.isArray(it)){
                it.forEach((itt, ii) => {
                    pointsSep.push({
                    x: itt.x,
                    y: itt.y,
                    direction: (line.ox2 - line.ox1) * (itt.x - line.ox1) + (line.oy2 - line.oy1) * (itt.y - line.oy1)  > 0 ? '1' : '0',
                    line:ll
                    })
                })
            }else{
                pointsSep.push({
                    x: it.x,
                    y: it.y,
                    direction: (line.ox2 - line.ox1) * (it.x - line.ox1) + (line.oy2 - line.oy1) * (it.y - line.oy1)  > 0 ? '1' : '0',
                    line:ll
                })
            }
        }
    })
    let forward, backward;
    if(pointsSep.length){
        forward = pointsSep
        .filter(i => i.direction == 0)
        .sort((a, b) => getDistance(lcX, lcY, a.x, a.y) - getDistance(lcX, lcY, b.x, b.y))[0];
        backward = pointsSep
            .filter(i => i.direction == 1)
            .sort((a, b) => getDistance(lcX, lcY, a.x, a.y) - getDistance(lcX, lcY, b.x, b.y))[0];
        if(!forward){
        forward = pointsSep
        .filter(i => i.x != backward.x || i.y != backward.y)
        .sort((a, b) => getDistance(lcX, lcY, a.x, a.y) - getDistance(lcX, lcY, b.x, b.y))[0];
        }    
        if(!backward){
        backward = pointsSep
        .filter(i => i.x != forward.x && i.y != forward.y)
        .sort((a, b) => getDistance(lcX, lcY, a.x, a.y) - getDistance(lcX, lcY, b.x, b.y))[0];
        }
    }
    return [forward, backward]
}

export function getMinMaxInTwoLine(twoLine, separators, p, type){
    let min = twoLine[0].line[type+'1']
    let max = twoLine[0].line[type+'2']
    twoLine.forEach((line, index) => {
        if(line && line.line){
           var cords =  getCordsInLine(line.line, separators);
           let mn = cords[0]
           let mx = cords[1]
           cords.forEach((cc, i) => {
                if(cc[type] < p && cc[type] > mn[type]){
                    mn = cc
                }
                if(cc[type] > p && cc[type] < mx[type]){
                    mx = cc
                }
           })
           if (line.line.r){
                var coordsA = findArcMinMax({x:mn.x, y: mn.y, r: Math.abs(line.line.r), or: line.line.r }, {x:mx.x, y: mx.y});
                if(type == 'x'){
                    if (coordsA.minX < min) min = coordsA.minX;
                    if (coordsA.maxX > max) max = coordsA.maxX;
                }else{
                    if (coordsA.minY < min) min = coordsA.minY;
                    if (coordsA.maxY > max) max = coordsA.maxY;
                }
            }else{
                if (mn[type] < min) min = mn[type];
                if (mx[type] > max) max = mx[type];
            }
        }
    })
    
    return {min, max}
}

export function getCordsInLine(ll, separators){
    var cords = [{x: ll.x1, y: ll.y1},{x: ll.x2, y: ll.y2}]
    separators.forEach((line, index) => {
        if(line.index != index){
            if(ll.r){
                if(isPointOnArc({x: ll.x1, y: ll.y1, r: Math.abs(ll.r), or: ll.r}, {x: ll.x2, y: ll.y2}, line.ox1, line.oy1, 1)){
                    cords.push({x:line.ox1,y:line.oy1});
                }
                if(isPointOnArc({x: ll.x1, y: ll.y1, r: Math.abs(ll.r), or: ll.r}, {x: ll.x2, y: ll.y2}, line.ox2, line.oy2, 1)){
                    cords.push({x:line.ox2,y:line.oy2});
                }
            }else{
                if (isPointOnLine(ll.x1, ll.y1, ll.x2, ll.y2, line.ox1, line.oy1, 1)) {
                    cords.push({x:line.ox1,y:line.oy1});
                }
                if (isPointOnLine(ll.x1, ll.y1, ll.x2, ll.y2, line.ox2, line.oy2, 1)) {
                    cords.push({x:line.ox2,y:line.oy2});
                }
            }
        }
    })
    cords = cords.filter((point, index, self) =>
        index === self.findIndex((p) => (
            p.x === point.x && p.y === point.y
        ))
    );
    return cords;
}

export function findAngleBetweenPoints(x1, y1, x2, y2) {
    const deltaX = x2 - x1;
    const deltaY = y2 - y1;
    const angleInRadians = Math.atan2(deltaY, deltaX);
    const angleInDegrees = angleInRadians * (180 / Math.PI); // Конвертация в градусы
    return angleInDegrees;
}

export function moveLinePerpendicular(x1, y1, x2, y2, distance, numLines) {
    // Вычисление вектора линии
    const dx = x2 - x1;
    const dy = y2 - y1;

    // Перпендикулярный вектор (нормализация и смена направлений)
    const length = Math.sqrt(dx * dx + dy * dy);
    const ux = -(dy / length); // Нормализованный вектор, перпендикулярный к линии AB
    const uy = dx / length;

    // Расстояние между параллельными линиями
    const step = distance / (numLines - 1);

    const lines = [];
    for (let i = 0; i < numLines; i++) {
        // Смещение на i-й интервал
        const offset = i * step - (distance / 2);

        // Перемещение линии вперед и назад на заданное расстояние
        const x1_new = x1 + ux * offset;
        const y1_new = y1 + uy * offset;
        const x2_new = x2 + ux * offset;
        const y2_new = y2 + uy * offset;

        lines.push({ x1: x1_new, y1: y1_new, x2: x2_new, y2: y2_new });
    }

    return lines;
}
export function getPathDataByPoints(points){
    var pathData = '';

    // Создаем путь для многоугольника
    for (var i = 0; i < points.length; i++) {
        var nextIndex = (i + 1) % points.length;
        if (i === 0) {
            pathData += `M ${points[i].x} ${points[i].y} `;
        }
        if (points[i].r) {
            pathData += `A ${points[i].radius} ${points[i].radius} 0 0 1 ${points[nextIndex].x} ${points[nextIndex].y} `;
        } else {
            // Если сторона - прямая линия
            pathData += `L ${points[nextIndex].x} ${points[nextIndex].y} `;
        }
    }
    pathData += 'Z'; // Замыкаем путь

    return pathData;
}

export function drawPolygon(points, type, lineIndex, colorridLines='gray', fill='') {
    var pathData = getPathDataByPoints(points);

    // Создаем многоугольник как путь
    const polygonPath = new fabric.Path(pathData, {
        fill: fill,
        stroke: 'black',
        strokeWidth: 1,
        selectable: false
    });

    // Создаем сетку внутри границ многоугольника
    const gridLines = [];
    const gridSize = 10; // Размер шага сетки

    const {minX, minY, maxX, maxY} = minMaxCords(points);

    // Добавляем вертикальные линии
    for (let x = minX; x <= maxX; x += gridSize) {
        gridLines.push(new fabric.Line([x, minY, x, maxY], {
            stroke: colorridLines,
            strokeWidth: 1,
            selectable: false,
        }));
    }

    // Добавляем горизонтальные линии
    for (let y = minY; y <= maxY; y += gridSize) {
        gridLines.push(new fabric.Line([minX, y, maxX, y], {
            stroke: colorridLines,
            strokeWidth: 1,
            selectable: false,
        }));
    }

    // Создаем группу для сетки и применяем к ней клиппинг
    const gridGroup = new fabric.Group(gridLines, {
        selectable: false,
        clipPath: polygonPath // Применяем клиппинг к сетке
    });

    // Создаем группу с многоугольником и сеткой
    const polygonWithGrid = new fabric.Group([polygonPath, gridGroup], {
        selectable: false,
        ctype: type,
        lineIndex: lineIndex
    });

    return polygonWithGrid;
}


export function normalizeLine(line) {
    // Деструктурируем координаты и остальные параметры из объекта линии
    const { x1, y1, x2, y2, ...rest } = line;

    // Проверяем и корректируем координаты, чтобы всегда была правильная упорядоченность
    if (x1 > x2 || (x1 === x2 && y1 > y2)) {
        return { x1: x2, y1: y2, x2: x1, y2: y1, ...rest };
    }

    // Если порядок правильный, возвращаем как есть
    return line;
}

export function normalizeLineO(line) {
    // Деструктурируем координаты и остальные параметры из объекта линии
    const { ox1, oy1, ox2, oy2, ...rest } = line;

    // Проверяем и корректируем координаты, чтобы всегда была правильная упорядоченность
    if (ox1 > ox2 || (ox1 === ox2 && oy1 > oy2)) {
        return { ox1: ox2, oy1: oy2, ox2: ox1, oy2: oy1, ...rest };
    }

    // Если порядок правильный, возвращаем как есть
    return line;
}

export function findParallelLine(points, x1, y1, x2, y2, distance) {
    // Вычисление вектора направления линии
    const dx = x2 - x1;
    const dy = y2 - y1;

    // Длина линии
    const length = Math.sqrt(dx * dx + dy * dy);

    // Нормализованный вектор, перпендикулярный к линии
    const ux = -(dy / length);
    const uy = dx / length;

    // Вычисление координат параллельной линии
    var line = shortenLine(x1 + ux * distance, y1 + uy * distance, x2 + ux * distance, y2 + uy * distance, 5)
    if(isPointInsidePolygon(points, {x:line.x1,y:line.y1})){
        line = shortenLine(x1 - ux * distance, y1 - uy * distance, x2 - ux * distance, y2 - uy * distance, 5)
    }
    return line;
}

export function shortenLine(x1, y1, x2, y2, distance) {
    // Вычисление вектора направления линии
    const dx = x2 - x1;
    const dy = y2 - y1;

    // Вычисление длины линии
    const length = Math.sqrt(dx * dx + dy * dy);

    // Нормализация вектора
    const ux = dx / length;
    const uy = dy / length;

    // Смещение точки (x1, y1) на расстояние distance в направлении (x2, y2)
    const newX1 = x1 + ux * distance;
    const newY1 = y1 + uy * distance;

    // Смещение точки (x2, y2) на расстояние distance в направлении (x1, y1)
    const newX2 = x2 - ux * distance;
    const newY2 = y2 - uy * distance;

    // Возвращаем новую укороченную линию
    return {
        x1: newX1,
        y1: newY1,
        x2: newX2,
        y2: newY2
    };
}

export function placeTextAlongArc(text, centerX, centerY, radius, startAngle, endAngle) {
    const totalAngle = endAngle - startAngle;

    // Переменные для расчета
    let currentAngle = startAngle;
    let arcLength = 0;
    let fittingText = '';

    // Рассчитываем суммарную ширину символов
    for (let i = 0; i < text.length; i++) {
        const sampleChar = new fabric.Text(text[i], { fontSize: 16 });
        const charWidth = sampleChar.width;

        // Проверяем, помещается ли символ на дугу
        const charArcLength = charWidth / radius;
        if (arcLength + charArcLength > totalAngle) {
            break;
        }

        fittingText += text[i];
        arcLength += charArcLength;
    }

    // Угол для каждого символа
    let textObjects = []

    for (let i = 0; i < fittingText.length; i++) {
        // Создаем текстовый объект для символа и вычисляем его ширину
        const sampleChar = new fabric.Text(fittingText[i], { fontSize: 16 });
        const charWidth = sampleChar.width;
    
        // Вычисляем угол, который должен занять этот символ на дуге
        const angleForChar = charWidth / radius;
    
        // Вычисляем координаты каждого символа на дуге
        const charX = centerX + radius * Math.cos(currentAngle + angleForChar / 2);
        const charY = centerY + radius * Math.sin(currentAngle + angleForChar / 2);
    
        // Добавляем символ на канвас
        const char = new fabric.Text(fittingText[i], {
            left: charX,
            top: charY,
            fontSize: 16,
            originX: 'center',
            fill: 'red',
            backgroundColor: 'white',
            originY: 'center',
            angle: (currentAngle + angleForChar / 2) * 180 / Math.PI + 90, // Угол поворота символа
            selectable: false, // Разрешить выбор объекта
            evented: false // Включить обработку событий
        });
    
        textObjects.push(char);
    
        // Смещаем угол для следующего символа
        currentAngle += angleForChar;
    }

    // Группируем текстовые объекты
    const group = new fabric.Group(textObjects, {
        selectable: false, // Делает группу выбираемой
        backgroundColor: 'white',
    });
    return group;
}
export function findPointOnArc(A, B, type, distance) {
    function extendLine(A, B, scale) {
        let x1 = A.x, y1 = A.y;
        let x2 = B.x, y2 = B.y;
        
        // Шаг 1: Вычисляем разницу по координатам
        let dx = x2 - x1;
        let dy = y2 - y1;
        
        // Шаг 2: Находим длину линии
        let distance = Math.sqrt(dx * dx + dy * dy);
        
        // Шаг 3: Единичный вектор направления
        let unitDx = dx / distance;
        let unitDy = dy / distance;
        
        // Шаг 4: Новые координаты точки B с учетом удлинения
        let newX = x1 + unitDx * (distance * scale);
        let newY = y1 + unitDy * (distance * scale);
        
        return { ox: newX, oy: newY };
    }
    const R = Math.abs(A.or)
    const center = calculateArcCenter({x:A.ox, y:A.oy, or: A.or}, {x:B.ox, y:B.oy, or: B.or}, Math.abs(A.or));
    let startAngle = Math.atan2(A.oy - center.y, A.ox - center.x);
    let endAngle = Math.atan2(B.oy - center.y, B.ox - center.x);
    if (endAngle < startAngle) {
        endAngle += 2 * Math.PI;
    }
    // Проверка, какой путь короче: прямой или противоположный
    if (endAngle - startAngle > Math.PI || (endAngle - startAngle == Math.PI && A.or < 0)) {
        let temp = startAngle;
        startAngle = endAngle;
        endAngle = temp + 2 * Math.PI;
    }
    let angleTotal = endAngle - startAngle;
    if (angleTotal > Math.PI) {
        angleTotal = 2 * Math.PI - angleTotal;
    }
    let arcLength = R * angleTotal;
    const n =  Math.floor(arcLength/distance)
    const angleStep = angleTotal / n;
    let currentAngle;
    if(type == 1){
        currentAngle = A.or < 0 ? endAngle - angleStep : startAngle + angleStep;
    }else{
        currentAngle = A.or < 0 ? endAngle - (angleTotal-angleStep) : startAngle + (angleTotal-angleStep);
    }

    // Найдем координаты новой точки на окружности
    let Px = center.x + R * Math.cos(currentAngle);
    let Py = center.y + R * Math.sin(currentAngle);

    return type == 1 ? extendLine({ x: A.ox, y: A.oy }, { x: Px, y: Py }, 10) : extendLine({ x: B.ox, y: B.oy }, { x: Px, y: Py }, 10)
}

export function shiftLineInTriangle(A, B, C, distance) {
    const dx = B.x - A.x;
    const dy = B.y - A.y;

    // Векторное произведение для определения ориентации
    const cross = dx * (C.y - A.y) - dy * (C.x - A.x);

    // Нормальный вектор внутрь треугольника
    let nx, ny;
    if (cross > 0) {
        nx = -dy; // Против часовой стрелки
        ny = dx;
    } else {
        nx = dy;  // По часовой стрелке
        ny = -dx;
    }

    // Нормализация нормального вектора
    const length = Math.sqrt(nx * nx + ny * ny);
    nx /= length;
    ny /= length;

    // Новые точки A' и B'
    const line = {
        x1: A.x + distance * nx,
        y1: A.y + distance * ny,
        x2: B.x + distance * nx,
        y2: B.y + distance * ny
    };
    return line;
}

export function shiftLineClosestToPoint(line, point, distance) {
    const dx = line.x2 - line.x1;
    const dy = line.y2 - line.y1;

    // Нормальный вектор
    const length = Math.sqrt(dx * dx + dy * dy);
    const nx = -dy / length;
    const ny = dx / length;

    // Два варианта сдвига линии
    const shiftedLine1 = {
        x1: line.x1 + distance * nx,
        y1: line.y1 + distance * ny,
        x2: line.x2 + distance * nx,
        y2: line.y2 + distance * ny
    };

    const shiftedLine2 = {
        x1: line.x1 - distance * nx,
        y1: line.y1 - distance * ny,
        x2: line.x2 - distance * nx,
        y2: line.y2 - distance * ny
    };

    // Вычисляем расстояния от точки до каждой линии
    const dist1 = getDistance(point.x, point.y, shiftedLine1.x1, shiftedLine1.y1)
    const dist2 = getDistance(point.x, point.y, shiftedLine2.x1, shiftedLine2.y1)

    // Возвращаем линию, которая ближе к точке
    return dist1 < dist2 ? shiftedLine1 : shiftedLine2;
}

export function getInsetPolygon(points, offset) {
    const calculateIntersection = (p1, p2, p3, p4) => {
        const denominator = (p4.y - p3.y) * (p2.x - p1.x) - (p4.x - p3.x) * (p2.y - p1.y);
        if (denominator === 0) {
            return null; // Линии параллельны
        }

        const ua = ((p4.x - p3.x) * (p1.y - p3.y) - (p4.y - p3.y) * (p1.x - p3.x)) / denominator;
        return {
            x: p1.x + ua * (p2.x - p1.x),
            y: p1.y + ua * (p2.y - p1.y)
        };
    };

    const offsetPoints = [];

    const n = points.length;
    for (let i = 0; i < n; i++) {
        // Предыдущая точка (с учетом замыкания многоугольника)
        let p0 = points[(i - 1 + n) % n];
        // Текущая точка
        const p1 = points[i];
        // Следующая точка (с учетом замыкания многоугольника)
        let p2 = points[(i + 1) % n];

        if(p1.or){
            p2 = findPointOnArc(p1, p2, 1, offset)
        }
        if(p0.or){
            p0 = findPointOnArc(p0, p1, 2, offset)
        }
        // console.log(p1,p2,p0)
        // Вектор для текущего отрезка
        const v1 = { x: p2.ox - p1.ox, y: p2.oy - p1.oy };
        const length1 = Math.sqrt(v1.x * v1.x + v1.y * v1.y);
        const normal1 = { x: -v1.y / length1, y: v1.x / length1 };

        // Вектор для предыдущего отрезка
        const v0 = { x: p1.ox - p0.ox, y: p1.oy - p0.oy };
        const length0 = Math.sqrt(v0.x * v0.x + v0.y * v0.y);
        const normal0 = { x: -v0.y / length0, y: v0.x / length0 };

        // Найдем смещенные точки
        const offsetPoint1 = {
            x: p1.ox + normal0.x * offset,
            y: p1.oy + normal0.y * offset
        };
        const offsetPoint2 = {
            x: p1.ox + normal1.x * offset,
            y: p1.oy + normal1.y * offset
        };
        // Найдем пересечение смещенных линий, чтобы получить новую вершину
        let newPoint = calculateIntersection(
            offsetPoint1, { x: offsetPoint1.x + v0.x, y: offsetPoint1.y + v0.y },
            offsetPoint2, { x: offsetPoint2.x + v1.x, y: offsetPoint2.y + v1.y }
        );
        if (!newPoint) {
            newPoint = {
                x: (offsetPoint1.x + offsetPoint2.x) / 2,
                y: (offsetPoint1.y + offsetPoint2.y) / 2
            };
        }
        if(p1.or) newPoint.r = p1.or < 0 ? p1.or + offset: p1.or - offset;
        offsetPoints.push(newPoint);
        
    }
    let chengPoints = [];
    for (let i = 0; i < points.length; i++) {
        let np = points[i];
        np.ox1 = offsetPoints[i].x
        np.oy1 = offsetPoints[i].y
        np.or1 = offsetPoints[i].r
        chengPoints.push(np);
    }
    return chengPoints;
}

export function alignPerpendicularLines(lineA, lineB) {
    // Деструктуризация линий для удобства
    let { x1: xA1, y1: yA1, x2: xA2, y2: yA2 } = lineA;
    let { x1: xB1, y1: yB1, x2: xB2, y2: yB2 } = lineB;

    // Если линия A горизонтальная (y1 === y2)
    if (yA1 === yA2) {
        // Линия A горизонтальная, проверяем для линии B
        if (xB1 === xB2) {
            // Линия B вертикальная
            const isB1Above = yB1 < yB2;
            if (isB1Above !== (yA1 < yA2)) {
                // Переставляем точки B, если не совпадают по направлению
                [xB1, yB1, xB2, yB2] = [xB2, yB2, xB1, yB1];
            }
        }
    } else if (xA1 === xA2) {
        // Линия A вертикальная, проверяем для линии B
        if (yB1 === yB2) {
            // Линия B горизонтальная
            const isB1Above = xB1 < xB2;
            if (isB1Above !== (xA1 < xA2)) {
                // Переставляем точки B, если не совпадают по направлению
                [xB1, yB1, xB2, yB2] = [xB2, yB2, xB1, yB1];
            }
        }
    }

    // Возвращаем выровненные линии
    return {
        line1: { x1: xA1, y1: yA1, x2: xA2, y2: yA2 },
        line2: { x1: xB1, y1: yB1, x2: xB2, y2: yB2 }
    };
}

export function getLastArticuleID(articulTypes){
    if (!articulTypes.length) return  null;

    articulTypes.sort((a, b) => b.pp1 - a.pp1); // Сортировка по убыванию pp1
    return articulTypes[0].id;
}