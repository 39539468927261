<template>
  <div>
    <main class="d-flex flex-nowrap">
      <div class="sidebar p-3 bg-body-tertiary" style="width: 300px;">
          <div class="list-group list-group-flush border-bottom scrollarea gap-2">
            <!-- <button class="btn btn-outline-secondary"> Сбросить </button> -->
            <button class="btn btn-outline-secondary" @click="addVertex"> Добавить вершину </button>
            <button class="btn btn-outline-danger" @click="removeVertex"> Удалить вершину </button>
            <div class="d-flex gap-2 justify-content-center">
              <button class="btn btn-outline-secondary p-0" style="height: 40px;width: 40px;" @click="addSeparators('H',2)">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 1H14C14.5523 1 15 1.44772 15 2V14C15 14.5523 14.5523 15 14 15H8.5V1ZM7.5 1H2C1.44772 1 1 1.44772 1 2V14C1 14.5523 1.44772 15 2 15H7.5V1ZM0 2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V2Z" fill="black"></path></svg>
              </button>
              <button class="btn btn-outline-secondary p-0" style="height: 40px;width: 40px;" @click="addSeparators('H',3)">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10 1H6V15H10V1ZM11 1V15H14C14.5523 15 15 14.5523 15 14V2C15 1.44772 14.5523 1 14 1H11ZM2 1H5V15H2C1.44772 15 1 14.5523 1 14V2C1 1.44772 1.44772 1 2 1ZM2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2Z" fill="black"></path>
                </svg>
              </button>
              <button class="btn btn-outline-secondary p-0" style="height: 40px;width: 40px;" @click="addSeparators('H',4)">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1 2C1 1.44772 1.44772 1 2 1H3.5V15H2C1.44772 15 1 14.5523 1 14V2ZM4.5 15V1H6.50009V15H4.5ZM7.50009 15H9.5V1H7.50009V15ZM12.5 15H10.5V1H12.5V15ZM13.5 15H14C14.5523 15 15 14.5523 15 14V2C15 1.44772 14.5523 1 14 1H13.5V15ZM3.5 16H4.5H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H3.5Z" fill="black"></path>
                </svg>
              </button>
              <button class="btn btn-outline-secondary p-0" style="height: 40px;width: 40px;transform: rotate(-90deg);" @click="addSeparators('V',2)">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 1H14C14.5523 1 15 1.44772 15 2V14C15 14.5523 14.5523 15 14 15H8.5V1ZM7.5 1H2C1.44772 1 1 1.44772 1 2V14C1 14.5523 1.44772 15 2 15H7.5V1ZM0 2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V2Z" fill="black"></path>
                </svg>
              </button>
              <button class="btn btn-outline-secondary p-0" style="height: 40px;width: 40px;transform: rotate(-90deg);" @click="addSeparators('V',3)">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10 1H6V15H10V1ZM11 1V15H14C14.5523 15 15 14.5523 15 14V2C15 1.44772 14.5523 1 14 1H11ZM2 1H5V15H2C1.44772 15 1 14.5523 1 14V2C1 1.44772 1.44772 1 2 1ZM2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2Z" fill="black"></path>
                </svg>
              </button>
              <button class="btn btn-outline-secondary p-0" style="height: 40px;width: 40px;transform: rotate(-90deg);" @click="addSeparators('V',4)">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1 2C1 1.44772 1.44772 1 2 1H3.5V15H2C1.44772 15 1 14.5523 1 14V2ZM4.5 15V1H6.50009V15H4.5ZM7.50009 15H9.5V1H7.50009V15ZM12.5 15H10.5V1H12.5V15ZM13.5 15H14C14.5523 15 15 14.5523 15 14V2C15 1.44772 14.5523 1 14 1H13.5V15ZM3.5 16H4.5H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H3.5Z" fill="black"></path>
                </svg>
              </button>
            </div>
            <button class="btn btn-outline-secondary" @click="addRadius"> Установить радиус </button>
            <button class="btn btn-outline-secondary" @click="addArcHeight"> Установить высоту дуги </button>
            <button class="btn btn-outline-secondary" @click="arcToSegment"> Разбить арку на сегменты </button>
            <button class="btn btn-outline-danger" @click="removeSeparators()"> Удалить разбивку </button>
            <button class="btn btn-outline-danger" @click="removeSeparators('all')"> Удалить всю разбивку </button>
            <hr>
            <button class="btn btn-outline-secondary" @click="addAdditionalProfile">Добавить доборный профиль</button>
            <button class="btn btn-outline-danger" @click="removeAdditionalProfile">Удалить доборный профиль</button>
            <!-- <button class="btn btn-outline-secondary"> Провести арку через промежутчные точки </button> -->
          </div>
      </div>
      <div class="constructor" ref="canvasContainer">
        <canvas id="constructor" :height="800" :width="800" ></canvas>
      </div>
    </main>
    <cModal
      id="segment"
      :visible="activeModal === 'segment'"
      @close="closeModal"
      title="Выводите количество сегментов"
      _class="modal-sm"
    >
      <template #default>
        <input type="number" class="form-control" v-model="countSegment">
      </template>
      <template #footer>
        <button @click="splitSegments()" class="btn btn-primary">ok</button>
      </template>
    </cModal>
    <cModal
      id="sizeoutput"
      :visible="activeModal === 'sizeoutput'"
      @close="closeModal"
      title="Вывод размера"
      _class="modal-sm"
    >
      <template #default>
        <input type="number" class="form-control" v-model="sizeoutput">
      </template>
      <template #footer>
        <button @click="sizeoutputFN()" class="btn btn-primary">ok</button>
      </template>
    </cModal>
    <cModal
      id="separators"
      :visible="activeModal === 'separators'"
      @close="closeModal"
      title="Свойства профиля"
      _class="modal-md"
    >
      <template #default>
        <div class="mb-3 text-start" v-if="separatorsMType==1">
          <label class="form-label mb-0">количество частей </label>
          <input type="number" class="form-control" v-model="addSeparatorsNum">
        </div>
        <div class="row mb-3">
          <div class="col">
            <div class="text-start">
              <label  class="form-label mb-0">профиль</label>
              <select class="form-select" v-model="selDividers">
                <option value="0">По умолчанию</option>
                <option v-for="divider in dividers" :value="divider.id" :key="divider.id">{{divider.or1_name}}</option>
              </select>
            </div>
          </div>
          <div class="col d-flex align-items-end">
            <div class="form-check">
              <input class="form-check-input mb-0" type="checkbox" v-model="dummyElement" id="defaultCheck2">
              <label class="form-check-label" for="defaultCheck2">Фиктивный элемент</label>
            </div>
          </div>
        </div>
        <div class="row"  v-if="separatorsMType==2">
          <div class="col">
            <div class="text-start">
              <label  class="form-label mb-0">Фиксирование длина</label>
              <input type="number" class="form-control" v-model="fixLength">
            </div>
          </div>
          <div class="col">
            <div class="text-start">
              <label  class="form-label mb-0">Добавить к размеру </label>
              <input type="number" class="form-control" v-model="addToSize">
            </div>
          </div>
        </div>
        <div class="row mt-3"  v-if="separatorsMType==2">
          <div class="col d-flex align-items-center">
            <div class="form-check">
              <input class="form-check-input mb-0" type="checkbox" v-model="notIncDimensions" id="defaultCheck1">
              <label class="form-check-label" for="defaultCheck1">Не учитывать габариты</label>
            </div>
          </div>
          <div class="col">
            <div class="text-start">
              <label  class="form-label mb-0">Фиксированный габарит</label>
              <input type="number" class="form-control" v-model="fixedDimension">
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <button @click="separatorsMType!=2 ? (setSeparators=true,closeModal('separators')) : editSeperator()" class="btn btn-primary">ok</button>
      </template>
    </cModal>
    <cModal
      id="additionalProfile"
      :visible="activeModal === 'additionalProfile'"
      @close="closeModal"
      title="Свойства доборного профиля"
      _class="modal-md"
    >
      <template #default>
        <div class="row mb-3">
          <div class="col">
            <div class="text-start">
              <label  class="form-label mb-0">профиль</label>
              <select class="form-select" v-model="APProf">
                <option v-for="addProfile in addProfiles" :value="addProfile.id" :key="addProfile.id">{{addProfile.or1_name}}</option>
              </select>
            </div>
          </div>
          <div class="col">
            <div class="text-start">
              <label class="form-label mb-0">количество</label>
              <input type="number" class="form-control" v-model="APNum">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="text-start">
              <label  class="form-label mb-0">Фиксирование длина</label>
              <input type="number" class="form-control" v-model="APFixLength">
            </div>
          </div>
          <div class="col">
            <div class="text-start">
              <label  class="form-label mb-0">Добавить к размеру </label>
              <input type="number" class="form-control" v-model="APAddToSize">
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <button @click="addProfseditoradd()" class="btn btn-primary">ok</button>
      </template>
    </cModal>
  </div>
</template>
<script >
import { mapGetters, mapActions, mapMutations } from "vuex";

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import cModal from './cModal.vue';
export default {
  name: "frameView",
  components: { cModal },
  data() {
      return {
        canvas: null,
        corners: null,
        selectedElement:null,
        activeModal: null,
        elements: [],
        boundingBox:[],
        isMouseDown: false,
        countSegment:null,
        scale:1,
        OFFSET:80,
        sizeoutput:null,
        sizeoutputType:null,
        aRadius: null,
        arcHeight:null,
        separatorsLine:[],
        addSeparatorsType:null,
        addSeparatorsNum:null,
        setSeparators:false,
        selDividers: null,
        dummyElement:false,
        separatorsMType: null,
        fixLength: 0,
        addToSize: 0,
        notIncDimensions: false,
        fixedDimension: 0,
        addProfsLines:[],
        APProf:null,
        APNum: 1,
        APFixLength: 0,
        APAddToSize: 0
      }
  },
  created() {

  },
  computed: {
    ...mapGetters("constr", ["addProfiles", "framePoints", "separators", "addProfs"]),
    ...mapGetters("profile", ["profsys","articuls"]),
    dividers() {
      return this.$store.getters['profile/articulTypes']([17]);
    },
  },
  async mounted() {
    await this.$store.dispatch('profile/getProfsys');
    await this.$store.dispatch('profile/getConstrTypes');
    if(!this.articuls.length) {
      await this.$store.dispatch('profile/getArticuls');
    }
    this.getaddProfiles()
    this.canvas = new fabric.Canvas('constructor', {
        selection: false, 
        backgroundColor: '#ddd',
    });
    window.addEventListener('resize', this.resizeCanvas);
    this.resizeCanvas();
    // this.drawGrid(20)
    this.updatePoints()
    this.drawBoundingBox()
    this.renderCanvas()
    this.renderCorners()
    this.renderSeparators()
    this.canvas.on('mouse:move', (e) => this.objectMoving(e));
    this.canvas.on('mouse:down', (e) => this.mouseDown(e));
    this.canvas.on('mouse:up', (e) => this.mouseUp(e));
    this.canvas.renderAll();
  },
  methods: {
    ...mapActions("constr", ["getDividers","getaddProfiles"]),
    ...mapMutations("constr", ['updateStateItem', 'removeArrayItem', 'addFramePointinIndex','updateArrayItemProperty', 'addArrayItem']),
    sizeoutputFN(){
      switch (this.sizeoutputType) {
        case 'radius':
          this.setRadius()
          break;
        case 'arcHeight':
          this.setArcHeight()
          break;
        case 'editLength':
          this.editLength()
          break;  
        default:
          break;
      }
    },
    addAdditionalProfile(){
      if(!this.selectedElement || this.selectedElement.ctype == 'vertex' || this.selectedElement.ctype == 'separators'){
        toast("Пожалуйста выберите линию !", {
          autoClose: 1000,
          type: "warning",
        });
        return;
      }
      const ap = this.addProfs.find(obj => obj.lineIndex === this.selectedElement.lineIndex)
      if(ap){
        this.APProf = ap.prof
        this.APNum  = ap.num
        this.APFixLength = ap.fixLength
        this.APAddToSize = ap.addToSize
      }else{
        this.APProf = this.addProfiles ? this.addProfiles[0].id : null
        this.APNum  = 1
        this.APFixLength = 0
        this.APAddToSize = 0
      }
      this.openModal('additionalProfile')
    },
    removeAdditionalProfile(){
      if(!this.selectedElement || this.selectedElement.ctype != 'addProfs'){
        toast("Пожалуйста выберите линию !", {
          autoClose: 1000,
          type: "warning",
        });
        return;
      }
      const index =this.addProfs.findIndex(obj => obj.lineIndex === this.selectedElement.lineIndex);
      this.removeArrayItem({property:'addProfs',index})
      this.clearCanvas()
      this.updateCanvas()
    },
    addProfseditoradd(){
      if(!this.selectedElement || this.selectedElement.ctype == 'vertex' || this.selectedElement.ctype == 'separators') return
      const index = this.selectedElement.lineIndex

      if(this.addProfs.find(obj => obj.lineIndex === index)){
        const i =this.addProfs.findIndex(obj => obj.lineIndex === index);
        this.updateArrayItemProperty({property:'addProfs', key:'prof', index:i, value: this.APProf})
        this.updateArrayItemProperty({property:'addProfs', key:'num', index:i, value: this.APNum})
        this.updateArrayItemProperty({property:'addProfs', key:'fixLength', index:i, value: this.APFixLength})
        this.updateArrayItemProperty({property:'addProfs', key:'addToSize', index:i, value: this.APAddToSize})
      }else{
        this.addArrayItem({property:'addProfs', item:{
          lineIndex:index,
          prof:  this.APProf,
          num:  this.APNum,
          fixLength:  this.APFixLength,
          addToSize:  this.APAddToSize,
        }})
      }
      this.clearCanvas()
      this.updateCanvas()
      this.closeModal('additionalProfile')
    },
    addProfsEdit(){
      if(!this.selectedElement || this.selectedElement.ctype != 'addProfs') return

      const ap = this.addProfs.find(obj => obj.lineIndex === this.selectedElement.lineIndex)
      if(ap){
        this.APProf = ap.prof
        this.APNum  = ap.num
        this.APFixLength = ap.fixLength
        this.APAddToSize = ap.addToSize
      }else{
        this.APProf = this.addProfiles ? this.addProfiles[0].id : null
        this.APNum  = 1
        this.APFixLength = 0
        this.APAddToSize = 0
      }
      this.openModal('additionalProfile')
    },
    removeSeparators(type){
      if(type == 'all'){
        this.updateStateItem({property:'separators',value:[]})
        this.separatorsLine = []
      }else{
        if(!this.selectedElement || this.selectedElement.ctype != 'separators'){
          toast("Пожалуйста выберите линю!", {
            autoClose: 1000,
            type: "warning",
          });
          return;
        }
        const index = this.selectedElement.lineIndex;
        this.removeArrayItem({property:'separators',index})
      }
      this.clearCanvas()
      this.updateCanvas()
    },
    addSeparators(type, num){
      this.addSeparatorsType = type
      this.addSeparatorsNum = num
      this.separatorsMType = num == 4 ? 1 : null
      this.selDividers = this.dividers.length ? this.dividers[0].id : null
      this.dummyElement = false
      this.openModal('separators')
    },
    editSeperator(){
      this.closeModal('separators')
      if(!this.selectedElement || this.selectedElement.ctype != 'separators') return

      const index = this.selectedElement.lineIndex
      this.updateArrayItemProperty({property:'separators', key:'prof', index, value: this.selDividers})
      this.updateArrayItemProperty({property:'separators', key:'dummyElement', index, value: this.dummyElement})
      this.updateArrayItemProperty({property:'separators', key:'fixLength', index, value: this.fixLength})
      this.updateArrayItemProperty({property:'separators', key:'addToSize', index, value: this.addToSize})
      this.updateArrayItemProperty({property:'separators', key:'notIncDimensions', index, value: this.notIncDimensions})
      this.updateArrayItemProperty({property:'separators', key:'fixedDimension', index, value: this.fixedDimension})
      this.updateArrayItemProperty({property:'separators', key:'drawLines', index, value: null})
      console.log(this.separators)
      this.clearCanvas()
      this.updateCanvas()
    },
    addSeparatorsLine(pointer){
      if(!this.setSeparators || this.addSeparatorsNum < 2) return
      
      if(this.$isPointInsidePolygon(this.framePoints, pointer)){
        let line = { 
          ox1: this.getOrigPoinst(Math.round(pointer.x), 'x'),
          oy1: this.getOrigPoinst(Math.round(pointer.y), 'y'), 
          ox2: this.getOrigPoinst(Math.round(pointer.x), 'x'),
          oy2: this.getOrigPoinst(Math.round(pointer.y), 'y'),
        }
        var type = 'y';
        if(this.addSeparatorsType == 'H'){
          type = 'x'
          line.ox2 = line.ox2 + 10
        }else{
          line.oy2 = line.oy2 + 10
        }
        const p = type == 'x' ? pointer.x : pointer.y;
        let lines = this.getAllLines('o');
        this.separators.map(function(line, i) {
          lines.push({
            x1:line.ox1,
            y1:line.oy1,
            x2:line.ox2,
            y2:line.oy2,
            index:i
          })
          if(line.drawLines){
            for (let i = 0; i < line.drawLines.length; i++) {
              lines.push(line.drawLines[i])
            }
          }
        })
        var twoLine = this.$lineLinesIntersections(line, lines);
        if(!twoLine.length) return;

        var {min, max} = this.$getMinMaxInTwoLine(twoLine, this.separators, p, type)

        // if(!min || !max) return;
        const step = ((max - min) / this.addSeparatorsNum).toFixed();
        for (let index = 1; index < this.addSeparatorsNum; index++) {
          if(this.addSeparatorsType === 'H'){
            line = { 
              ox1: min+(step*index),
              oy1: this.getOrigPoinst(Math.round(pointer.y), 'y'), 
              ox2: min+(step*index),
              oy2: this.getOrigPoinst(Math.round(pointer.y), 'y') + 10,
              prof: this.selDividers||null,
              dummyElement: this.dummyElement||false
            }
          }else{
            line = { 
              ox1: this.getOrigPoinst(Math.round(pointer.x), 'x'),
              oy1: min+(step*index), 
              ox2: this.getOrigPoinst(Math.round(pointer.x), 'x')+10,
              oy2: min+(step*index),
              prof: this.selDividers||null,
              dummyElement: this.dummyElement||false
            }
          }
          this.addArrayItem({property:'separators', item:line})
        }
        this.clearCanvas()
        this.updateCanvas()
      }
      this.setSeparators = false
    },
    addVertex(){
      if(!this.selectedElement || this.selectedElement.ctype == 'vertex' || this.selectedElement.ctype == 'separators'){
        toast("Пожалуйста выберите линию !", {
          autoClose: 1000,
          type: "warning",
        });
        return;
      }

      const index = this.selectedElement.lineIndex;
      const nextIndex = (index + 1) % this.framePoints.length;
      let point;
      if (this.selectedElement instanceof fabric.Line) {
        point = this.$findMidpoint({x:this.framePoints[index].ox, y:this.framePoints[index].oy, r: this.framePoints[index].or}, {x:this.framePoints[nextIndex].ox, y:this.framePoints[nextIndex].oy, r: this.framePoints[nextIndex].or});
      }
      // Проверка для дуг (path)
      if (this.selectedElement instanceof fabric.Circle) {
        point = this.$findArcMidpoint({x:this.framePoints[index].ox, y:this.framePoints[index].oy, r: this.framePoints[index].or}, {x:this.framePoints[nextIndex].ox, y:this.framePoints[nextIndex].oy, r: this.framePoints[nextIndex].or});
      }
      if(point){
        this.addFramePointinIndex({point:{ox:point.x,oy:point.y,or:this.framePoints[index].or}, k:index+1})
        this.clearCanvas()
        this.updateCanvas()
      }
    },
    addArcHeight(){
      if(!this.selectedElement || this.selectedElement.ctype == 'vertex' || this.selectedElement.ctype == 'separators'){
        toast("Пожалуйста выберите линию!", {
          autoClose: 1000,
          type: "warning",
        });
        return;
      }
      const index = this.selectedElement.lineIndex;
      this.sizeoutput = null;
      if(this.framePoints[index].or){
        var nextIndex = (index + 1) % this.framePoints.length;
        this.sizeoutput = this.cFixed(this.$calculateHeightByR(this.framePoints[index].ox, this.framePoints[index].oy, this.framePoints[nextIndex].ox, this.framePoints[nextIndex].oy, this.framePoints[index].or))
      }
      this.sizeoutputType = 'arcHeight'
      this.openModal('sizeoutput')
    },
    addRadius(){
      if(!this.selectedElement || this.selectedElement.ctype == 'vertex' || this.selectedElement.ctype == 'separators'){
        toast("Пожалуйста выберите линию!", {
          autoClose: 1000,
          type: "warning",
        });
        return;
      }
      const index = this.selectedElement.lineIndex;
      this.sizeoutput = this.framePoints[index].or||null;
      this.sizeoutputType = 'radius';
      this.openModal('sizeoutput')
    },
    arcToSegment(){
      if(!this.selectedElement || this.selectedElement.ctype == 'vertex' || this.selectedElement.ctype == 'separators' || !(this.selectedElement instanceof fabric.Circle)){
        toast("Пожалуйста выберите арку!", {
          autoClose: 1000,
          type: "warning",
        });
        return;
      }
      this.countSegment = null;
      this.openModal('segment')
    },
    splitSegments(){
      if(!this.selectedElement || this.countSegment <= 0) return;

      const index = this.selectedElement.lineIndex;
      if(this.countSegment > 1){
        const nextIndex = (index + 1) % this.framePoints.length;
        let points = this.$findPointsOnArc(this.framePoints[index], this.framePoints[nextIndex], this.countSegment+1);
        points.shift();
        points.pop();
        for (let i = 0; i < points.length; i++) {
          this.addFramePointinIndex({point:{ox:points[i].x,oy:points[i].y,or:this.framePoints[index].or}, k:index+1+i})
        }
      }
      this.closeModal('segment')
      this.clearCanvas()
      this.updateCanvas()
    },
    editLength(){
      if(this.sizeoutput == this.editLengthData.len) return;

      let d = (this.sizeoutput - this.editLengthData.len)
      const xy = this.editLengthData.type;
      const start = this.editLengthData.from;
      const end = this.editLengthData.to;
      const type = this.editLengthData.all;
      if(type == 3){
        var lines = this.separators.map((line, index) => ({ line, index })).filter(item => item.line[xy+'2'] == end || item.line[xy+'2'] == end)
        var se = end
        if(!lines.length){
          lines = this.separators.map((line, index) => ({ line, index })).filter(item => item.line[xy+'2'] == start || item.line[xy+'2'] == start)
          d = (this.editLengthData.len-this.sizeoutput)
          se = start
        } 
        
        if(lines.length){
          lines.forEach((item, i) => {
            if(item.line[xy+'1'] == se){
              this.updateArrayItemProperty({property:'separators', key:'o'+xy+'1', index:item.index, value: this.separators[item.index]['o'+xy+'1'] + d})
            }
            if(item.line[xy+'2'] == se){
              this.updateArrayItemProperty({property:'separators', key:'o'+xy+'2', index:item.index, value: this.separators[item.index]['o'+xy+'2'] + d})
            }
          })
        }
      }else{
        if(this.framePoints.find(point => point[xy] == start)){
          this.framePoints.forEach((point, index) => {
            if(point[xy] > start){
              this.framePoints[index]['o'+xy] += d;
            }
          })
        }else{
          console.log(start)
        }
      }
      this.closeModal('sizeoutput')
      this.clearCanvas()
      this.updateCanvas()
    },
    setArcHeight(){
      if(!this.selectedElement || this.selectedElement.ctype == 'vertex' || this.selectedElement.ctype == 'separators'){
        toast("Пожалуйста выберите линию!", {
          autoClose: 1000,
          type: "warning",
        });
        this.closeModal('sizeoutput')
        return;
      }
      const index = this.selectedElement.lineIndex;
      var radius = null;
      if(this.sizeoutput && this.sizeoutput != 0){
        const nextIndex = (index + 1) % this.framePoints.length;
        radius = this.$calculateRadiusByH(this.framePoints[index].ox, this.framePoints[index].oy, this.framePoints[nextIndex].ox, this.framePoints[nextIndex].oy, this.sizeoutput)
        const dx = this.framePoints[nextIndex].ox - this.framePoints[index].ox;
        const dy = this.framePoints[nextIndex].oy - this.framePoints[index].oy;
        const distance = Math.sqrt(dx * dx + dy * dy);
        const minRadius = distance / 2;
        if (Math.abs(radius) < minRadius) {
          radius = radius < 0 ? minRadius * -1 : minRadius;
          this.sizeoutput = this.cFixed(this.$calculateHeightByR(this.framePoints[index].ox, this.framePoints[index].oy, this.framePoints[nextIndex].ox, this.framePoints[nextIndex].oy, radius))
          toast("Всота дуги не может быть меньше "+this.sizeoutput, {
            autoClose: 1000,
            type: "warning",
          });
          return;
        }
      }
      this.closeModal('sizeoutput')
      this.updateArrayItemProperty({property:'framePoints', key:'or', index, value: radius})
      this.clearCanvas()
      this.updateCanvas()
    },
    setRadius(){  
      if(!this.selectedElement || this.selectedElement.ctype == 'vertex' || this.selectedElement.ctype == 'separators'){
        toast("Пожалуйста выберите линию!", {
          autoClose: 1000,
          type: "warning",
        });
        this.closeModal('sizeoutput')
        return;
      }
      const index = this.selectedElement.lineIndex;
      if(this.sizeoutput && this.sizeoutput != 0){
        var nextIndex = (index + 1) % this.framePoints.length;
        const dx = this.framePoints[nextIndex].ox - this.framePoints[index].ox;
        const dy = this.framePoints[nextIndex].oy - this.framePoints[index].oy;
        const distance = Math.sqrt(dx * dx + dy * dy);
        const minRadius = distance / 2;
        if (Math.abs(this.sizeoutput) < minRadius) {
          this.sizeoutput = this.sizeoutput < 0 ? minRadius * -1 : minRadius;
          toast("Радус не может быть меньше "+this.sizeoutput, {
            autoClose: 1000,
            type: "warning",
          });
          return;
        }
      }
      this.closeModal('sizeoutput')
      this.updateArrayItemProperty({property:'framePoints', key:'or', index, value: this.sizeoutput||null})
      this.clearCanvas()
      this.updateCanvas()
    },
    removeVertex(){
      if(this.framePoints.length <= 3){
        toast("Вы достигли минимального количества вершин!", {
          autoClose: 1000,
          type: "warning",
        });
        return;
      }
      if(!this.selectedElement || this.selectedElement.ctype != 'vertex'){
        toast("Пожалуйста выберите вершину!", {
          autoClose: 1000,
          type: "warning",
        });
        return;
      }
      const index = this.selectedElement.lineIndex;
      this.removeArrayItem({property:'framePoints',index})
      this.clearCanvas()
      this.updateCanvas()
    },
    clearCanvas(){
      this.corners=null
      this.selectedElement = null
      this.elements =  []
      this.boundingBox = []
      this.canvas.clear();
      this.canvas.setBackgroundColor('#ddd');
    },
    updateCanvas(){
      this.updatePoints()
      this.drawBoundingBox()
      this.renderCanvas()
      this.renderCorners()
      this.renderSeparators()
    },
    renderCanvas(){
      if(!this.framePoints.length) return false;
      
      this.elements = this.createElements(this.framePoints);
      this.elements.forEach(elem => this.canvas.add(elem));
    },
    resizeCanvas(e = false) {
      const container = this.$refs.canvasContainer;
      const containerWidth = Math.floor(container.clientWidth / 50) * 50;
      const containerHeight = Math.floor(container.clientHeight / 50) * 50;
      this.canvas.setWidth(containerWidth);
      this.canvas.setHeight(containerHeight);
      this.canvas.renderAll();
      if(e){
          // this.drawGrid(20)
          this.updatePoints()
          this.drawBoundingBox();
          this.updateElements(this.framePoints);
          this.updateCorners();
          this.updateSeparatorsLine()
          this.canvas.renderAll();
      }
    },
    updatePoints(){
      this.updateStateItem({property:'framePoints',value:this.$checkRadius(this.framePoints)})
      this.$store.dispatch('constr/updateSeparators');
      const spr = this.$getScalePoinst(this.framePoints, this.canvas.width, this.canvas.height, this.OFFSET, this.separators)
      this.scale = spr.scale;
      this.updateStateItem({property:'framePoints',value:spr.points})
      this.updateStateItem({property:'separators',value:spr.separators})
      this.mx = spr.mx;
      this.left = spr.left;
      this.top = spr.top;
      console.log(spr)
    },
    updateSeparators(){
      var _self = this;
      let lines = this.getAllLines('o');
      this.separators.map(function(line, i) {
        const prof = line.prof ? _self.dividers.find(obj => obj.id === line.prof) : null;
        let distance = prof && (!line.fixedDimension || parseInt(line.fixedDimension) < 1) ? prof.wr : parseInt(line.fixedDimension)*2;
        var data = _self.$lineLinesIntersections(line, lines);
        if(data.length){
          if(data[0] && _self.separators[i].ox2 == data[0].x && _self.separators[i].oy2 == data[0].y && data[1]){
            _self.separators[i].ox1 = data[1].x
            _self.separators[i].oy1 = data[1].y
          }else if(data[0] && _self.separators[i].ox1 == data[0].x && _self.separators[i].oy1 == data[0].y && data[1]){
            _self.separators[i].ox2 = data[1].x
            _self.separators[i].oy2 = data[1].y 
          }else if(data[1] && _self.separators[i].ox1 == data[1].x && _self.separators[i].oy1 == data[1].y && data[0]){
            _self.separators[i].ox2 = data[0].x
            _self.separators[i].oy2 = data[0].y 
          }else if(data[1] && _self.separators[i].ox2 == data[1].x && _self.separators[i].oy2 == data[1].y && data[0]){
            _self.separators[i].ox1 = data[0].x
            _self.separators[i].oy1 = data[0].y 
          }else{
            if(data[0]){
              _self.separators[i].ox1 = data[0].x
              _self.separators[i].oy1 = data[0].y
            }
            if(data[1]){
              _self.separators[i].ox2 = data[1].x
              _self.separators[i].oy2 = data[1].y 
            }
          }
        }
        if(distance >= 10 && !line.notIncDimensions){
          const mlp = _self.$moveLinePerpendicular(line.ox1, line.oy1, line.ox2, line.oy2, distance, _self.getRoundedSteps(distance, 20))
          _self.separators[i].drawLines = []
          mlp.map(function(linelp, j) {
            var newL = {
              ox1:linelp.x1,
              oy1:linelp.y1,
              ox2:linelp.x2,
              oy2:linelp.y2,
              lt: '-'
            }
            var data = _self.$lineLinesIntersections(newL, lines);
            if(data.length){
              if(data[0] && newL.ox2 == data[0].x && newL.oy2 == data[0].y && data[1]){
                newL.ox1 = data[1].x
                newL.oy1 = data[1].y
                newL.or1 = data[1].line ? data[1].line.r : null
              }else if(data[0] && newL.ox1 == data[0].x && newL.oy1 == data[0].y && data[1]){
                newL.ox2 = data[1].x
                newL.oy2 = data[1].y 
                newL.or2 = data[1].line ? data[1].line.r : null
              }else if(data[1] && newL.ox1 == data[1].x && newL.oy1 == data[1].y && data[0]){
                newL.ox2 = data[0].x
                newL.oy2 = data[0].y
                newL.or2 = data[0].line ? data[0].line.r : null 
              }else if(data[1] && newL.ox2 == data[1].x && newL.oy2 == data[1].y && data[0]){
                newL.ox1 = data[0].x
                newL.oy1 = data[0].y 
                newL.or1 = data[0].line ? data[0].line.r : null 
              }else{
                if(data[0]){
                  newL.ox1 = data[0].x
                  newL.oy1 = data[0].y
                  newL.or1 = data[0].line ? data[0].line.r : null
                }
                if(data[1]){
                  newL.ox2 = data[1].x
                  newL.oy2 = data[1].y 
                  newL.or2 = data[1].line ? data[1].line.r : null 
                }
              }
            }
            newL = _self.$normalizeLine({
                x1:newL.ox1,
                y1:newL.oy1,
                x2:newL.ox2,
                y2:newL.oy2
              })
            _self.separators[i].drawLines.push(newL)
            if (j === 0 || j === mlp.length - 1) {
              lines.push({
                index:i,
                lt: '-',
                ...newL
              })
            }
          })
        }else{
          _self.separators[i].drawLines = []
          lines.push({
            x1:_self.separators[i].ox1,
            y1:_self.separators[i].oy1,
            x2:_self.separators[i].ox2,
            y2:_self.separators[i].oy2,
            index:i
          })
        }
      })
    },
    renderCorners(){
      var _self = this;
      this.corners = this.framePoints.map(function(point, index) {
        var circle = new fabric.Circle({
            left: point.x - 5,
            top: point.y - 5,
            radius: 5,
            fill: 'black',
            // stroke: 'black',
            strokeWidth: 1,
            hasControls: false,
            hasBorders: false,
            selectable: false
        });

        circle.lineIndex = index;
        circle.ctype = 'vertex';
        _self.canvas.add(circle);
        return circle;
      });
    },
    separatorsEdit(){
      if(!this.selectedElement || this.selectedElement.ctype != 'separators') return

      const index = this.selectedElement.lineIndex;
      const sep = this.separators[index];
      
      this.separatorsMType = 2
      this.selDividers = sep.prof ? sep.prof : null
      this.dummyElement = sep.dummyElement||false
      this.fixLength = sep.fixLength ? sep.fixLength : 0
      this.addToSize = sep.addToSize ? sep.addToSize : 0
      this.notIncDimensions = sep.notIncDimensions||false
      this.fixedDimension = sep.fixedDimension ? sep.fixedDimension : 0
      this.openModal('separators')
    },
    separatorsSelect(index){
      this.unSelectElement()
      this.selectedElement = this.separatorsLine[index].line
      this.updateSeparatorsLine()
      this.canvas.renderAll();
    },
    renderSeparators(type){
      var _self = this;
      this.separatorsLine = []
      this.separators.map(function(point, index) {
        const prof = point.prof ? _self.dividers.find(obj => obj.id === point.prof) : null;
        var circle1 = new fabric.Circle({
            left: point.x1 - 5,
            top: point.y1 - 5,
            radius: 5,
            fill: '#4d4d4d',
            // stroke: 'black',
            strokeWidth: 1,
            hasControls: false,
            hasBorders: false,
            selectable: false,
            ctype:'svertex',
            lineIndex: index,
            pointIndex: 1
        });
        var circle2 = new fabric.Circle({
            left: point.x2 - 5,
            top: point.y2 - 5,
            radius: 5,
            fill: '#4d4d4d',
            // stroke: 'black',
            strokeWidth: 1,
            hasControls: false,
            hasBorders: false,
            selectable: false,
            ctype:'svertex',
            lineIndex: index,
            pointIndex: 2
        });
        const angle = _self.$findAngleBetweenPoints(point.x1, point.y1, point.x2, point.y2)
        var angleText = new fabric.Text(angle.toFixed(1).toString()+'°', {
            left: point.x1 + 0.75 * (point.x2 - point.x1),
            top: point.y1 + 0.75 * (point.y2 - point.y1),
            fontSize: 14, // Размер текста
            fill: 'red', // Цвет текста
            backgroundColor: 'white', // Фон текста
            originX: 'center',
            originY: 'center',
            // angle: angle, // Учет уклона линии
            selectable: false, // Разрешить выбор объекта
            evented: false // Включить обработку событий
        });  
        var line;  
        if(point.drawLines && point.drawLines.length){
            let _points = []
            let drawLines = point.drawLines
            const normalizPoint = _self.$normalizeLine({
                x1:point.x1,
                y1:point.y1,
                x2:point.x2,
                y2:point.y2
              })
            var pp;
            for (let i = 0; i < drawLines.length; i++) {
              if(i*2 == drawLines.length){
                _points.push({x:normalizPoint.x1,y:normalizPoint.y1});
              }
              pp = {x:_self.getRendPoinst(drawLines[i].x1, 'x'),y:_self.getRendPoinst(drawLines[i].y1, 'y')};
              // if(_self.$isPointInsidePolygonLines(_self.framePoints, pp)){
              _points.push(pp)
              // }
            }
            for (let i = drawLines.length - 1; i >= 0; i--) {
              pp = {x:_self.getRendPoinst(drawLines[i].x2, 'x'),y:_self.getRendPoinst(drawLines[i].y2, 'y')};
              // if(_self.$isPointInsidePolygonLines(_self.framePoints, pp)){
              _points.push(pp)
              // }
              if(i*2 == drawLines.length){
                _points.push({x:normalizPoint.x2,y:normalizPoint.y2});
              }
            } 
            const color = point.dummyElement ? 'red' : 'grey';
            line = _self.$drawPolygon(_points, 'separators', index, color );
            _self.canvas.add(line) 
        }else{
          line = new fabric.Line([point.x1, point.y1, point.x2, point.y2], {
                stroke: '#4d4d4d',
                strokeWidth: 3,
                hasControls: false,
                selectable: false,
                lineIndex: index,
                ctype: 'separators'
            });
            _self.canvas.add(line) 
        }
        var profText = null 
        if(prof){
            profText = new fabric.Text(prof.or1_name.toString(), {
              left: (point.x1 + point.x2) / 2,
              top: (point.y1 + point.y2) / 2,
              fontSize: 10, // Размер текста
              fill: 'black', // Цвет текста
              backgroundColor: 'white', // Фон текста
              originX: 'center',
              originY: 'center',
              angle: angle, // Учет уклона линии
              selectable: false, // Разрешить выбор объекта
              evented: false // Включить обработку событий
            });
            _self.canvas.add(profText)
        }
        if(type && _self.selectedElement && _self.selectedElement.lineIndex == index){
          if(_self.selectedElement.ctype == 'svertex'){
            _self.selectedElement = _self.selectedElement.pointIndex == 1 ? circle1 : circle2;
          }
          if(_self.selectedElement.ctype == 'separators'){
            _self.selectedElement = line;
          }
        }
        _self.canvas.add(angleText,circle1,circle2)
        _self.separatorsLine.push({line,circle1,circle2,profText,angleText})
      });
    },
    mouseUp(e){
      this.isMouseDown = false
    },
    mouseDown(e){
      this.isMouseDown = true
      var pointer = this.canvas.getPointer(e.e);
      var target = null;

      for (var s = 0; s < this.separatorsLine.length; s++) {
          if (this.$isCornersElement(pointer, this.separatorsLine[s].circle1)) {
            target = this.separatorsLine[s].circle1;
            break;
          }
          if (this.$isCornersElement(pointer, this.separatorsLine[s].circle2)) {
            target = this.separatorsLine[s].circle2;
            break;
          }
      }
      if(!target){
        for (var sl = 0; sl < this.separatorsLine.length; sl++) {
          const index = this.separatorsLine[sl].circle1.lineIndex
          const line = this.separators[index];
          const prof = line.prof ? this.dividers.find(obj => obj.id === line.prof) : null;
          let distance = prof && (!line.fixedDimension || parseInt(line.fixedDimension) < 1) ? prof.wr/2 : parseInt(line.fixedDimension);
          distance = distance > 5 ? distance*this.scale : 3;
          if (this.$isPointOnLine(
            this.separatorsLine[sl].circle1.left+this.separatorsLine[sl].circle1.radius, 
            this.separatorsLine[sl].circle1.top+this.separatorsLine[sl].circle1.radius,  
            this.separatorsLine[sl].circle2.left+this.separatorsLine[sl].circle2.radius,
            this.separatorsLine[sl].circle2.top+this.separatorsLine[sl].circle2.radius,  
            pointer.x, pointer.y, distance
          )) {
            target = this.separatorsLine[sl].line;
            break;
          }
        }
      }
      if(target && target.ctype == 'separators'){
        const currentTime = new Date().getTime();
        const timeSinceLastClick = currentTime - this.lastClickTime;
        if (timeSinceLastClick < 300) {
          return this.separatorsEdit()
        }
        this.lastClickTime = currentTime;
      }
      if(!target){
        for (var j = 0; j < this.corners.length; j++) {
            if (this.$isCornersElement(pointer, this.corners[j])) {
              target = this.corners[j];
              break;
            }
        }
      }
      if(!target){
        // Проверяем попадание в элемент
        for (var i = 0; i < this.elements.length; i++) {
            if (this.isPointNearElement(pointer, i)) {
              target = this.elements[i];
              break;
            }
        }
      }
      if(!target){
        // Проверяем попадание в элемент
        for (var api = 0; api < this.addProfsLines.length; api++) {
            if (this.isPointNearAddProfs(pointer, api)) {
              target = this.addProfsLines[api].elem;
              break;
            }
        }
      }
      if(target && target.ctype == 'addProfs'){
        const currentTime = new Date().getTime();
        const timeSinceLastClick = currentTime - this.lastClickTime2;
        if (timeSinceLastClick < 300) {
          return this.addProfsEdit()
        }
        this.lastClickTime2 = currentTime;
      }
      if (target) {
          this.unSelectElement()
          this.selectedElement = target;
          if(this.selectedElement.ctype == 'vertex' || this.selectedElement.ctype == 'svertex'){
              this.selectedElement.set({ fill: 'red' });
              // Создаем горизонтальную линию
              var hc = [this.minMax.minX-20, this.selectedElement.top+5, this.minMax.maxX, this.selectedElement.top+5]
              var vc = [this.selectedElement.left+5, this.minMax.minY-20, this.selectedElement.left+5, this.minMax.maxY]
              if(this.selectedElement.ctype == 'svertex'){
                hc = [this.minMax.minX, this.selectedElement.top+5, this.minMax.maxX+20, this.selectedElement.top+5]
                vc = [this.selectedElement.left+5, this.minMax.minY, this.selectedElement.left+5, this.minMax.maxY+20]
              }
              this.horizontalLine = new fabric.Line(hc, {
                  stroke: 'red',
                  strokeDashArray: [5, 5],
                  selectable: false,
                  evented: false,
                  // visible: false
              });

              // Создаем вертикальную линию
              this.verticalLine = new fabric.Line(vc, {
                  stroke: 'red',
                  strokeDashArray: [5, 5],
                  selectable: false,
                  evented: false,
                  // visible: false
              });
              this.canvas.add(this.horizontalLine, this.verticalLine);
          }else{
            if(this.selectedElement.ctype == 'separators' && this.selectedElement.type == "group"){
              this.selectedElement.getObjects('path').forEach((path) => {
                  path.set({ stroke: 'blue' }); // Устанавливаем цвет обводки
              });
            }
            this.selectedElement.set({ stroke: 'blue' });
          }
          this.canvas.renderAll();
      }else{
        this.addSeparatorsLine(pointer)
      }
    },
    objectMoving(e){
      if (!this.selectedElement || !this.isMouseDown) return;

      const pointer = this.canvas.getPointer(e.e);
      let x = Math.round(pointer.x);
      let y = Math.round(pointer.y);
      if (this.selectedElement.type === 'circle' && this.selectedElement.ctype == 'vertex') {
          var index = this.selectedElement.lineIndex;

          this.updateArrayItemProperty({property:'framePoints', index, key:'ox', value: Math.round(x - this.framePoints[index].x) + this.framePoints[index].ox})
          this.updateArrayItemProperty({property:'framePoints', index, key:'oy', value: Math.round(y - this.framePoints[index].y) + this.framePoints[index].oy})

          for (var i = 0; i < this.framePoints.length; i++) {
            if(i === index) continue;

            if (Math.abs(this.framePoints[index].ox - this.framePoints[i].ox) < 30) {
              this.updateArrayItemProperty({property:'framePoints', key:'ox', index, value: this.framePoints[i].ox})
            }
            if (Math.abs(this.framePoints[index].oy - this.framePoints[i].oy) < 30) {
              this.updateArrayItemProperty({property:'framePoints', key:'oy', index, value: this.framePoints[i].oy})
            }
          }
          this.updatePoints()
          // Обновляем элементы
          this.drawBoundingBox();
          this.updateElements(this.framePoints);
          this.updateCorners();
          this.updateSeparatorsLine()
          this.updateVHLines();
          this.canvas.renderAll();
      }
      if (this.selectedElement.type === 'circle' && this.selectedElement.ctype == 'svertex') {
          index = this.selectedElement.lineIndex;
          var k = this.selectedElement.pointIndex;
          this.updateArrayItemProperty({property:'separators', key:'ox'+k, index, value: Math.round(x - this.separators[index]['x'+k]) + this.separators[index]['ox'+k]})
          this.updateArrayItemProperty({property:'separators', key:'oy'+k, index, value: Math.round(y - this.separators[index]['y'+k]) + this.separators[index]['oy'+k]})

          if (Math.abs(this.separators[index].ox1 - this.separators[index].ox2) < 20) {
            if(k==1){
              this.updateArrayItemProperty({property:'separators', key:'ox1', index, value: this.separators[index].ox2})
            }else{
              this.updateArrayItemProperty({property:'separators', key:'ox2', index, value: this.separators[index].ox1})
            }
          }
          if (Math.abs(this.separators[index].oy1 - this.separators[index].oy2) < 20) {
            if(k==1){
              this.updateArrayItemProperty({property:'separators', key:'oy1', index, value: this.separators[index].oy2})
            }else{
              this.updateArrayItemProperty({property:'separators', key:'oy2', index, value: this.separators[index].oy1})
            }
          }
          this.updatePoints()
          // Обновляем элементы
          this.drawBoundingBox();
          // this.updateElements(this.points);
          // this.updateCorners();
          this.updateSeparatorsLine()
          this.updateVHLines();
          this.canvas.renderAll();
      }
    },
    unSelectElement(){
      if (this.selectedElement) {
          if(this.selectedElement.ctype == 'vertex'){
              this.selectedElement.set({ fill: 'black' });
          }else{
            this.selectedElement.set({ stroke: 'black' });
          }
          if (this.selectedElement.ctype == 'svertex') {
            this.selectedElement.set({ fill: '#4d4d4d' });
          }
          if (this.selectedElement.ctype == 'separators') {
            const index = this.selectedElement.lineIndex
            if(this.selectedElement.type == "group"){
              this.selectedElement.getObjects('path').forEach((path) => {
                  path.set({ stroke: 'black' }); // Устанавливаем цвет обводки
              });
            }else{
              this.separatorsLine[index].line.set({ stroke: '#4d4d4d' });
            }
            this.separatorsLine[index].angleText.set({ fill: 'red' });
            if(this.separatorsLine[index].profText){
              this.separatorsLine[index].profText.set({ fill: 'black' });
            }
          }
          if(this.horizontalLine){
            this.canvas.remove(this.horizontalLine, this.verticalLine);
            this.horizontalLine=this.verticalLine = null
          }
      }
    },
    createAddProfs(point1, point2, addProfs, index){
      let elem,textelem;
      const prof = addProfs.prof && this.addProfiles ? this.addProfiles.find(obj => obj.id === addProfs.prof) : null;
      var text = '('+addProfs.num.toString() + ')';
      if(prof){
        text = text+'   '+prof.or1_name.toString();
      } 
      if (point1.r) {
        const coords = this.$getArcCoords(point1, point2);
        const lt = point1.or && point1.or < 0 ? 15 : -15;
        const nr = point1.or && point1.or < 0 ? -15 : 15;
        elem = new fabric.Circle({
                left: coords.left+lt,
                top: coords.top+lt,
                radius: coords.radius+nr,
                startAngle: coords.startAngle,
                endAngle: coords.endAngle,
                stroke: 'black',
                strokeWidth: 3,
                fill: '',
                angle: 0,
                selectable: false,
                strokeDashArray: [10, 5],
                lineIndex: index,
                ctype: 'addProfs'
            });
        textelem = this.$placeTextAlongArc(text.toString(), coords.left+coords.radius, coords.top+coords.radius, coords.radius+nr, coords.startAngle, coords.endAngle)
      }else{
        let lcords = this.$findParallelLine(this.framePoints, point1.x, point1.y, point2.x, point2.y, 15)
        // lcords = this.$normalizeLine(lcords)
        elem = new fabric.Line([lcords.x1, lcords.y1, lcords.x2, lcords.y2], {
                  stroke: 'black', strokeWidth: 3,strokeDashArray: [10, 5], hasControls: false, selectable: false, lineIndex: index, ctype: 'addProfs'
              });
        var angle = Math.atan2(lcords.y2-lcords.y1, lcords.x2 - lcords.x1); 
        textelem = new fabric.Text(text.toString(), {
            left: (lcords.x2 + lcords.x1)/2,
            top: (lcords.y2 + lcords.y1)/2,
            fontSize: 16, // Размер текста
            fill: 'red', // Цвет текста
            backgroundColor: 'white', // Фон текста
            originX: 'center',
            originY: 'center',
            angle: angle * (180 / Math.PI), // Учет уклона линии
            selectable: false, // Разрешить выбор объекта
            evented: true // Включить обработку событий
         });   
      }
      this.addProfsLines.push({elem,textelem})
      this.canvas.add(elem);
      if(textelem){
        this.canvas.add(textelem); 
      }
      if(this.selectedElement && this.selectedElement.ctype == 'addProfs' && this.selectedElement.lineIndex == index){
        this.selectedElement = elem;
        this.selectedElemen.set({ stroke: 'blue' });
      }
    },
    createElements(points) {
      var elements = [];
      this.addProfsLines = []
      for (var i = 0; i < points.length; i++) {
          var nextIndex = (i + 1) % points.length;
          const addProfs = this.addProfs.find(obj => obj.lineIndex === i)
          if(addProfs){
            this.createAddProfs(points[i], points[nextIndex], addProfs, i)
          }
          if (points[i].r) {
              // Создаем дугу
              var coords = this.$getArcCoords(points[i], points[nextIndex]);
              const arc = new fabric.Circle({
                  left: coords.left,
                  top: coords.top,
                  radius: coords.radius,
                  startAngle: coords.startAngle,
                  endAngle: coords.endAngle,
                  stroke: 'black',
                  strokeWidth: 3,
                  fill: '',
                  angle: 0,
                  selectable: false,
                  lineIndex: i
              });
              elements.push(arc);
          } else {
              // Создаем линию
              var line = new fabric.Line([points[i].x, points[i].y, points[nextIndex].x, points[nextIndex].y], {
                  stroke: 'black', strokeWidth: 3, hasControls: false, selectable: false, lineIndex: i
              });
              elements.push(line);
          }
      }
      return elements;
    },
    updateElements(points) {
      for (var pl = 0; pl < this.addProfsLines.length; pl++) {
        this.canvas.remove(this.addProfsLines[pl].elem)
        this.canvas.remove(this.addProfsLines[pl].textelem)
      }
      this.addProfsLines = []
      for (var i = 0; i < points.length; i++) {
          var nextIndex = (i + 1) % points.length;
          const addProfs = this.addProfs.find(obj => obj.lineIndex === i)
          if(addProfs){
            this.createAddProfs(points[i], points[nextIndex], addProfs, i)
          }
          if (points[i].r) {
              // Обновляем дугу
              var coords = this.$getArcCoords(points[i], points[nextIndex]);
              this.elements[i].set({
                left: coords.left,
                top: coords.top,
                radius: coords.radius,
                startAngle: coords.startAngle,
                endAngle: coords.endAngle
              });
          } else {
              // Обновляем линию
              this.elements[i].set({ x1: points[i].x, y1: points[i].y, x2: points[nextIndex].x, y2: points[nextIndex].y });
          }
      }
    },
    updateVHLines(){
      if(!this.selectedElement) return;

      if(this.selectedElement.ctype == 'svertex'){
        if(this.verticalLine){
          this.verticalLine.set({ x1: this.selectedElement.left+5, y1: this.minMax.minY, x2: this.selectedElement.left+5, y2: this.minMax.maxY+20 });
        }

        if(this.horizontalLine){
          this.horizontalLine.set({ x1: this.minMax.minX, y1: this.selectedElement.top+5, x2: this.minMax.maxX+20, y2: this.selectedElement.top+5 });
        }
      }else{
        if(this.verticalLine){
          this.verticalLine.set({ x1: this.selectedElement.left+5, y1: this.minMax.minY-20, x2: this.selectedElement.left+5, y2: this.minMax.maxY });
        }

        if(this.horizontalLine){
          this.horizontalLine.set({ x1: this.minMax.minX-20, y1: this.selectedElement.top+5, x2: this.minMax.maxX, y2: this.selectedElement.top+5 });
        }
      }
    },
    updateCorners(){
      this.framePoints.forEach((point, index) => {
        this.corners[index].set({ left: point.x-5, top: point.y-5, fill: 'black' });
        if (this.selectedElement && this.selectedElement.ctype == 'vertex' && this.corners[index].lineIndex == this.selectedElement.lineIndex) {
          this.corners[index].set({ fill: 'red' });
        }
      });
    },
    updateSeparatorsLine(){
      this.separators.forEach((line, index) => {
        this.canvas.remove(this.separatorsLine[index].line)
        this.canvas.remove(this.separatorsLine[index].circle1)
        this.canvas.remove(this.separatorsLine[index].circle2)
        this.canvas.remove(this.separatorsLine[index].angleText)
        if(this.separatorsLine[index].profText){
          this.canvas.remove(this.separatorsLine[index].profText)
        }
      });
      this.separatorsLine = []
      this.renderSeparators(true)
      if (this.selectedElement){
        const index = this.selectedElement.lineIndex
        if (this.selectedElement.ctype == 'separators') {
          if(this.selectedElement.type == "group"){
            this.selectedElement.getObjects('path').forEach((path) => {
                path.set({ stroke: 'blue' }); // Устанавливаем цвет обводки
            });
          }else{
            this.separatorsLine[index].line.set({ stroke: 'blue' });
          }
          this.separatorsLine[index].angleText.set({ fill: 'black' });
          if(this.separatorsLine[index].profText){
            this.separatorsLine[index].profText.set({ fill: 'red' });
          }
        }
        if (this.selectedElement.ctype == 'svertex') {
          this.separatorsLine[index]['circle'+this.selectedElement.pointIndex].set({ fill: 'red' });
        }
      }
    },
    isPointNearElement(point, i) {
        var element = this.elements[i];
        if (element instanceof fabric.Line) {
          var x1 = element.x1, y1 = element.y1, x2 = element.x2, y2 = element.y2;
          return this.$isPointOnLine(x1, y1, x2, y2, point.x, point.y);
        }
        // Проверка для дуг (path)
        if (element instanceof fabric.Circle) {
            var nextIndex = (i + 1) % this.framePoints.length;
            return this.$isPointOnArc(this.framePoints[i], this.framePoints[nextIndex], point.x, point.y);
        }
        return false;
    },
    isPointNearAddProfs(point, i) {
        var element = this.addProfsLines[i].elem;
        const index = element.lineIndex
        const nextIndex = (index + 1) % this.framePoints.length;
        if (element instanceof fabric.Line) {
          var x1 = element.x1, y1 = element.y1, x2 = element.x2, y2 = element.y2;
          return this.$isPointOnLine(x1, y1, x2, y2, point.x, point.y);
        }
        // Проверка для дуг (path)
        if (element instanceof fabric.Circle) {
            return this.$isPointAddProfOnArc(this.framePoints[index], this.framePoints[nextIndex], point.x, point.y, element.radius);
        }
        return false;
    },
    drawBoundingBox() {
      // Удаляем предыдущий ограничивающий прямоугольник, если он существует
      if (this.boundingBox.length) {
        this.boundingBox.forEach(element => {
          this.canvas.remove(element);
        });
        this.boundingBox=[];
      }

      // Находим минимальные и максимальные координаты всех объектов с учетом радиусов
      const {minX, minY, maxX, maxY} = this.$minMaxCords(this.framePoints);
      this.minMax = {minX, minY, maxX, maxY};
      var uniqueX = {},uniqueY = {};
      this.framePoints.forEach(point => {
        uniqueX['k'+point.ox] = point.x
        uniqueY['k'+point.oy] = point.y
      });
      uniqueX['k'+this.mx.minX] = this.minMax.minX
      uniqueX['k'+this.mx.maxX] = this.minMax.maxX
      uniqueY['k'+this.mx.minY] = this.minMax.minY
      uniqueY['k'+this.mx.maxY] = this.minMax.maxY
      uniqueX = Object.entries(uniqueX).sort((a, b) => { return Number(a[1]) - Number(b[1]);});
      uniqueY = Object.entries(uniqueY).sort((a, b) => { return Number(a[1]) - Number(b[1]);});
      
      this.createArrowWithLength(minY, maxY, minX, 60, Math.abs(this.mx.minY - this.mx.maxY), 'y', 1);
      this.createArrowWithLength(minX, maxX,  minY, 60, Math.abs(this.mx.minX - this.mx.maxX), 'x', 1);
      var length;
      if(uniqueY.length > 2){
        for (let iy = 0; iy < uniqueY.length; iy++) {
          length = Math.abs(parseFloat(uniqueY[iy][0].slice(1)) - parseFloat(uniqueY[iy+1][0].slice(1)));
          this.createArrowWithLength(uniqueY[iy][1], uniqueY[iy+1][1], minX, 40, length, 'y');
          if(iy+2 == uniqueY.length) break;
        }
      }
      if(uniqueX.length > 2){
        for (let ix = 0; ix < uniqueX.length; ix++) {
          length = Math.abs(parseFloat(uniqueX[ix][0].slice(1)) - parseFloat(uniqueX[ix+1][0].slice(1)));
          this.createArrowWithLength(uniqueX[ix][1], uniqueX[ix+1][1], minY, 40, length, 'x');
          if(ix+2 == uniqueX.length) break;
        }
      }

      var uniquesX = {},uniquesY = {};
      this.separators.forEach(point => {
        uniquesX['k'+Math.trunc(point.ox1)] = point.x1
        uniquesX['k'+Math.trunc(point.ox2)] = point.x2
        uniquesY['k'+Math.trunc(point.oy1)] = point.y1
        uniquesY['k'+Math.trunc(point.oy2)] = point.y2
      });
      uniquesX['k'+Math.trunc(this.mx.minX)] = this.minMax.minX
      uniquesX['k'+Math.trunc(this.mx.maxX)] = this.minMax.maxX
      uniquesY['k'+Math.trunc(this.mx.minY)] = this.minMax.minY
      uniquesY['k'+Math.trunc(this.mx.maxY)] = this.minMax.maxY
      uniquesX = Object.entries(uniquesX).sort((a, b) => { return Number(a[1]) - Number(b[1]);});
      uniquesY = Object.entries(uniquesY).sort((a, b) => { return Number(a[1]) - Number(b[1]);});
      if(uniquesX.length > 2){
        for (let sx = 0; sx < uniquesX.length; sx++) {
          length = Math.abs(parseFloat(uniquesX[sx][0].slice(1)) - parseFloat(uniquesX[sx+1][0].slice(1)));
          this.createArrowWithLength(uniquesX[sx][1], uniquesX[sx+1][1], maxY, -40, length, 'x', 3);
          if(sx+2 == uniquesX.length) break;
        }
      }
      if(uniquesY.length > 2){
        for (let sy = 0; sy < uniquesY.length; sy++) {
          length = Math.abs(parseFloat(uniquesY[sy][0].slice(1)) - parseFloat(uniquesY[sy+1][0].slice(1)));
          this.createArrowWithLength(uniquesY[sy][1], uniquesY[sy+1][1], maxX, -40, length, 'y', 3);
          if(sy+2 == uniquesY.length) break;
        }
      }
      
    },
    createArrowWithLength(from, to, min, d, length, type, all=2) {
        length = parseFloat(length.toFixed(1))
        const fromX = type == 'x' ? from : min-d;
        const fromY = type == 'y' ? from : min-d;
        const toX = type == 'x' ? to : min-d;
        const toY = type == 'y' ? to : min-d;

        var angle = Math.atan2(toY - fromY, toX - fromX);
        var headLength = 10; // Длина стрелки

        // Координаты стрелок на концах
        var arrowX1 = toX - headLength * Math.cos(angle - Math.PI / 6);
        var arrowY1 = toY - headLength * Math.sin(angle - Math.PI / 6);
        var arrowX2 = toX - headLength * Math.cos(angle + Math.PI / 6);
        var arrowY2 = toY - headLength * Math.sin(angle + Math.PI / 6);

        var arrowX3 = fromX + headLength * Math.cos(angle - Math.PI / 6);
        var arrowY3 = fromY + headLength * Math.sin(angle - Math.PI / 6);
        var arrowX4 = fromX + headLength * Math.cos(angle + Math.PI / 6);
        var arrowY4 = fromY + headLength * Math.sin(angle + Math.PI / 6);

        var line = new fabric.Line([fromX, fromY, toX, toY], {
            fill: 'black',
            stroke: 'black',
            strokeWidth: 1,
            selectable: false // Невозможно выбрать объект
        });

        var arrowHead1 = new fabric.Polygon([
            { x: toX, y: toY },
            { x: arrowX1, y: arrowY1 },
            { x: arrowX2, y: arrowY2 }
        ], {
            fill: 'black',
            selectable: false // Невозможно выбрать объект
        });

        var arrowHead2 = new fabric.Polygon([
            { x: fromX, y: fromY },
            { x: arrowX3, y: arrowY3 },
            { x: arrowX4, y: arrowY4 }
        ], {
            fill: 'black',
            selectable: false // Невозможно выбрать объект
        });

        const lline =  new fabric.Group([line, arrowHead1, arrowHead2], {
            selectable: false, // Невозможно выбрать объект
            hasControls: false
        });

        // Создаем текстовый элемент с фоном
        var text = new fabric.Text(length.toString(), {
            left: (fromX + toX) / 2,
            top: (fromY + toY) / 2,
            fontSize: 12, // Размер текста
            fill: 'red', // Цвет текста
            backgroundColor: 'white', // Фон текста
            originX: 'center',
            originY: 'center',
            angle: angle * 180 / Math.PI, // Учет уклона линии
            selectable: false, // Разрешить выбор объекта
            evented: true // Включить обработку событий
        });
        text.on('mousedown', (e) => this.onTextClick(e, to, from, type, all, length));

        this.boundingBox.push(lline,text)
        this.canvas.add(lline,text);
    },
    onTextClick(e, to, from, type, all, length){
      this.editLengthData = {e, to, from, type, all, len:length}
      this.sizeoutputType = 'editLength'
      this.sizeoutput = length;
      this.openModal('sizeoutput')
    },
    drawGrid(gridSize) {
        var gridLines = [];
        for (var i = 0; i < (this.canvas.width / gridSize); i++) {
            var distance = i * gridSize;
            
            // Вертикальные линии
            var vertical = new fabric.Line([distance, 0, distance, this.canvas.height], {
                stroke: '#ccc',
                selectable: false,
                evented: false
            });

            // Горизонтальные линии
            var horizontal = new fabric.Line([0, distance, this.canvas.width, distance], {
                stroke: '#ccc',
                selectable: false,
                evented: false
            });

            // Добавляем линии на канвас
            gridLines.push(vertical);
            gridLines.push(horizontal);
        }
        
        // Добавляем линии на канвас
        this.canvas.add(...gridLines);
        this.canvas.sendToBack(...gridLines); // Отправляем линии на задний план
    },
    onlyUnique(value, index, array) {
      return array.indexOf(value) === index;
    },
    openModal(id) {
      this.activeModal = id;
    },
    closeModal(id) {
      if (this.activeModal === id) {
        this.activeModal = null;
      }
    },
    addUniqueNumber(arr, num) {
        // Проверка, есть ли в массиве число, которое на 1 больше или меньше
        const exists = arr.some(item => Math.abs(item - num) <= 1);

        // Если такого числа нет, добавляем новое число в массив
        if (!exists) {
            arr.push(num);
        }
        
        return arr;
    },
    cFixed(num, decimals=2) {
        // Проверяем, является ли число целым
        if (Number.isInteger(num)) {
            return num; // Для целых чисел возвращаем число без изменений
        }
        
        // Если число дробное, используем toFixed для округления
        return num.toFixed(decimals);
    },
    getOrigPoinst(val, type){
      if(type == 'x'){
        return (val - this.OFFSET - this.left) / this.scale + this.mx.minX
      }
      if(type == 'y'){
        return (val - this.OFFSET - this.top) / this.scale + this.mx.minY
      }
    },
    getRendPoinst(val, type){
      if(type == 'x'){
        return (val - this.mx.minX) * this.scale + this.OFFSET + this.left
      }
      if(type == 'y'){
        return (val - this.mx.minY) * this.scale + this.OFFSET + this.top
      }
    },
    getRoundedSteps(distance, d) {
        let steps = Math.floor(distance / d);
        if (steps % 2 !== 0) {
            steps--; // Если количество шагов нечетное, уменьшаем на 1
        }
        return Math.max(steps, 2);
    },
    getAllLines(k=''){
      var lines = []
      this.framePoints.forEach((point, index) => {
        var nextIndex = (index + 1) % this.framePoints.length;
        lines.push({
          x1:this.framePoints[index][k+'x'],
          y1:this.framePoints[index][k+'y'],
          x2:this.framePoints[nextIndex][k+'x'],
          y2:this.framePoints[nextIndex][k+'y'],
          r:this.framePoints[index][k+'r']
        })
      })
      return lines;
    }

  }
}
</script>